import { INIT_CREATE_PROJECT_PROFILE,
  CREATE_PROJECT_PROFILE_SUCCESS,
  CREATE_PROJECT_PROFILE_FAILURE,
  INIT_PROJECT_PROFILE_UPDATE,
PROJECT_PROFILE_UPDATE_SUCCESS,
PROJECT_PROFILE_UPDATE_FAILURE,
INIT_PROJECT_PROFILE_DELETE,
PROJECT_PROFILE_DELETE_SUCCESS,
PROJECT_PROFILE_DELETE_FAILURE
 } from "../utils/constants"

const initialState = {
  isLoading: false,
  showMessage: false,
  updatedMessageDialog: {},
  error: null,
  response: {},
  success:false,
  deleteSuccess:false,
  updateSuccess:false,
};

const projectProfileReducer = (state = initialState, action) => {

  switch (action.type) {

    case INIT_CREATE_PROJECT_PROFILE: {

      return {
        ...state,
        user: action.payload,
        isLoading: true
      };
    }
    case CREATE_PROJECT_PROFILE_SUCCESS: {

      const message = {
        show: true,
        title: "Project profile status",
        message:"Project profile created successfully.",
        messageType: "success",
      }

      return {
        ...state,
        isLoading: false,
        response: action.payload,
        updatedMessageDialog: message,
        showMessage: true,
        success: true,
      }
    } 
    case CREATE_PROJECT_PROFILE_FAILURE: {

      const message = {
        show: true,
        title: "Project profile status",
        message: action.payload,
        messageType: "danger",
      }

      return {
        ...state,
        isLoading: false,
        error: action.payload,
        updatedMessageDialog: message,
        showMessage: true,
      }
    }

    case INIT_PROJECT_PROFILE_UPDATE: {

      return {
        ...state,
        user: action.payload,
        isLoading: true
      };
    }

    case PROJECT_PROFILE_UPDATE_SUCCESS: {

      const message = {
        show: true,
        title: "Project profile update status",
        message:"Project profile updated successfully.",
        messageType: "success",
      }

      return {
        ...state,
        isLoading: false,
        response: action.payload,
        updatedMessageDialog: message,
        showMessage: true,
        updateSuccess: true,
      }
    } 
    case PROJECT_PROFILE_UPDATE_FAILURE: {

      const message = {
        show: true,
        title: "Project profile update status",
        message: action.payload,
        messageType: "danger",
      }

      return {
        ...state,
        isLoading: false,
        error: action.payload,
        updatedMessageDialog: message,
        showMessage: true,
      }
    }

    case INIT_PROJECT_PROFILE_DELETE: {

      return {
        ...state,
        user: action.payload,
        isLoading: true
      };
    }

    case PROJECT_PROFILE_DELETE_SUCCESS: {

      const message = {
        show: true,
        title: "Project profile delete status",
        message:"Project profile deleted successfully.",
        messageType: "success",
      }

      return {
        ...state,
        isLoading: false,
        response: action.payload,
        updatedMessageDialog: message,
        showMessage: true,
        deleteSuccess: true,

      }
    } 
    case PROJECT_PROFILE_DELETE_FAILURE: {

      const message = {
        show: true,
        title: "Project profile delete status",
        message: action.payload,
        messageType: "danger",
      }

      return {
        ...state,
        isLoading: false,
        error: action.payload,
        updatedMessageDialog: message,
        showMessage: true,
      }
    }

    default:
      return state;
  }
};


export default projectProfileReducer;
