import React from "react";
import PageHeader from "../../components/PageHeader";
import {  PD_USER } from "../../utils/constants";
import ProjectImplementationDetailsList from "../Dashbord/ProjectImplementationDetailsList";


export default function PDUserDashbord(): JSX.Element {

  return (
    <div className="dashboard-container">
      
      <PageHeader
        HeaderText="Project implementation details"
        Breadcrumb={[{ name: "project-implementation-details" }]}
      />
      
      <div className={`dashboard-container-summary`}>
       <ProjectImplementationDetailsList user_type={PD_USER} />
      </div>
    </div>
  )
}


