
const image1 = require('./image1.jpg')
const image2 = require('./image2.jpg')
const image3 = require('./image3.jpg')
const image5 = require('./image5.jpg')
const image6 = require('./image6.jpg')
const image7 = require('./image7.jpg')
const image8 = require('./image8.jpg')
const image10 = require('./image10.jpg')


const images = [
    image1,
    image2,
    image5,
    image3,
    image6,
    image7,
    image8,
    image10
]

export default images