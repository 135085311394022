import React from 'react'
import { useHistory } from "react-router"
import { TENANT_USER, ADMIN_USER } from "../../utils/constants"
import Button from 'react-bootstrap/Button'
import { useFetch } from "../../customHooks/fetchData"
import { getProjectSupportListURL } from "../../utils/allurls"
import { userId } from "../../utils/userUtils"
import { useSelector, useDispatch } from "react-redux";
import { getProjectUpdateJsonBody } from "../../utils/GeneralUtils"
import { updateProjectSupportRequestStatus, deleteProjectSupport } from "../../actions/projectSupportAction"
import swal from 'sweetalert';
import StatusInfo from '../../utils/StatusInfo'

export default function ProjecSupportList(props) {

    const history = useHistory()
    const dispatch = useDispatch()
    const projectSupportReducer = useSelector(state => state.projectSupportReducer)

    var requestURL;
    if (props.user_type === TENANT_USER) {
        requestURL = getProjectSupportListURL(userId(), "")
    } else if (props.user_type === ADMIN_USER) {
        requestURL = getProjectSupportListURL("", "")
    }

    const { loading, data, success } = useFetch(requestURL)
    console.log("Project support list", data)

  

    const deleteProjectSupportRequest = (extendRequestId) => {
        dispatch(deleteProjectSupport(extendRequestId))
    }

    const projectUpdateSuccess = (success) => {

        if (success) {
            swal("Project support update status", "Updated succesfully", "success")
                .then(function () {
                    window.location.reload(false)
                });
        }

    }

    const projectDeleteSuccess = (success) => {

        if (success) {
            swal("Project support delete status", "Project support deleted succesfully", "success")
                .then(function () {
                    window.location.reload(false)
                });
        }
    }

    return (
        <><div className="col-lg-12">
            <div className="card product_item_list product-order-list">
                <div className="header align-space-between-side">
                    <h2>Project support list</h2>
                    {(props.user_type === TENANT_USER) && <Button onClick={(e) => {
                        history.push("create-support")
                    }} variant="primary">

                        {"Create support"}
                    </Button>}
                </div>
                <div className="body">
                    <div className="table-responsive">
                        <table className="table table-hover m-b-0">
                            <thead className="thead-dark">
                                <tr>
                                    <th>ID</th>
                                    <th>Subject</th>
                                    <th>Details</th>
                                    <th>Project name</th>
                                    <th data-breakpoints="sm xs">Schedule Name</th>

                                    <th data-breakpoints="xs">Start date</th>
                                    <th data-breakpoints="sm xs md">Status</th>
                                    <th data-breakpoints="xs md">Action</th>
                                    <th data-breakpoints="xs md">View</th>

                                </tr>
                            </thead>
                            <tbody>
                                {loading && "Loading..."}
                                {success && (data.data).map((project, index) => (<tr 
                                 onClick={() => {

                                    history.push({pathname:"admin-chat-support-dashboard", state: project})


                                }}
                                key={index}>
                                    <td>{project.id}</td>
                                    <td><div class="request-top" style={{ whiteSpace: 'pre-wrap', overflowWrap: 'break-word' }}>
                                        <p>{project.subject}</p>
                                    </div></td>
                                    <td><div class="request-top" style={{ whiteSpace: 'pre-wrap', overflowWrap: 'break-word' }}>
                                        <p>{project.details}</p>
                                    </div></td>
                                    <td>{project.project_name}</td>
                                    <td>{project.schedule_name}</td>

                                    <td>
                                        {project.start_date}
                                    </td>
                                    <td>
                                        <StatusInfo status={project.status} />

                                    </td>
                                    {(props.user_type === TENANT_USER) && <td>
                                        <div>
                                            {project.status === 'open' && <Button onClick={() => {

                                                const jsonBody = getProjectUpdateJsonBody(project.id, "closed")

                                                dispatch(updateProjectSupportRequestStatus(jsonBody))

                                            }} >{"Close"}</Button>}

                                        </div>

                                        {/* <a className="btn btn-outline-secondary mr-1">
                                           <i className="icon-pencil"></i>
                                       </a> */}

                                    </td>}

                                    {(props.user_type === ADMIN_USER) && <td>
                                        <div class="request-top verticalDisplay" style={{ whiteSpace: 'pre-wrap', overflowWrap: 'break-word' }}>
                                            {project.status === 'open' && <Button variant="secondary" onClick={() => {

                                                const jsonBody = getProjectUpdateJsonBody(project.id, "closed")

                                                dispatch(updateProjectSupportRequestStatus(jsonBody))

                                            }} >{"Close"}</Button>

                                            }

                                            {project.status === 'open' && <Button onClick={() => {

                                                const jsonBody = getProjectUpdateJsonBody(project.id, "resolved")

                                                dispatch(updateProjectSupportRequestStatus(jsonBody))

                                            }} >{"Resolve"}</Button>

                                            }

                                            <a className="btn btn-outline-secondary">
                                                <i className="icon-trash" onClick={() => { deleteProjectSupportRequest(project.id) }} ></i>
                                            </a>


                                        </div>


                                    </td>}

                                    <td>
                                        <Button variant="secondary" onClick={() => {

                                            history.push({pathname:"admin-chat-support-dashboard", state: project})


                                        }} >{"Details"}</Button>

                                    </td>

                                    {projectUpdateSuccess(projectSupportReducer.updateSuccess)}
                                    {projectDeleteSuccess(projectSupportReducer.deleteSuccess)}

                                </tr>))}


                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div> </>
    )
}
