import React, { useState } from "react";
import Button from 'react-bootstrap/Button'
import Spinner from 'react-bootstrap/Spinner'
import PageHeader from "../../components/PageHeader"
import { useHistory } from "react-router"
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { getDateOneFormatToAnother } from "../../utils/DateUtils"
import { useSelector, useDispatch } from "react-redux";
import { createProjectSupportRequest } from "../../actions/projectSupportAction";
import Message from "../../utils/Message";
import { userId } from "../../utils/userUtils";
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import { useFetch } from "../../customHooks/fetchData"
import { getProjectProfileListURL, getProjectScheduleListURL } from "../../utils/allurls"
import { BASE_HEADERS } from "../../utils/baseValues";
import axios from 'axios'
import { getAllBhtpaContractPersonURL } from "../../utils/allurls"


export default function CreateProjectSupport() {

  const history = useHistory();
  const dispatch = useDispatch()
  const projectSupportReducer = useSelector(state => state.projectSupportReducer)
  const [projectId, setProjectId] = useState()
  const [scheduleId, setScheduleId] = useState()
  const [bhtpaContactId, setbhtpaContactId] = useState()
  const [scheduleList, setScheduleList] = useState(null)

  const initProjectSupport = {
    tenant_id: userId(),
    project_id: "",
    schedule_id: "",
    bhtpa_contact_id: "",
    subject: "",
    details: "",
    start_date: "",
    tenant_onsite_contact_name: "",
    tenant_onsite_contact_phone: "",
    status: "open",
  }


  const { loading, data, success } = useFetch(getProjectProfileListURL("approved", userId()))
  const contactList = useFetch(getAllBhtpaContractPersonURL)
  const [projectSupportData, setProjectSupportData] = useState(initProjectSupport)
  const [project_start_date_picker, set_project_start_date_picker] = useState(new Date());

  const handleOnchange = (e) => {
    const fieldName = e.target.name
    const fieldValue = e.target.value
    setProjectSupportData({
      ...projectSupportData,
      [fieldName]: fieldValue
    })

    console.log("projectSupportData : ", projectSupportData)
  }

  const projectIdChange = (event) => {
    const projec_id = event.target.value
    setProjectId(projec_id);
  
    setProjectSupportData({
      ...projectSupportData,
      project_id: projec_id
    })

    getScheduleList(projec_id)

  };

  const getScheduleList = async (projec_id) => {

    try {

      const headers = BASE_HEADERS()
      const response = await axios.get(getProjectScheduleListURL(userId(),"","",projec_id), { headers });

      if (response.status === 200) {

        setScheduleList(response.data.data)

      } else {
        setScheduleList(null)
      }

   } catch (error) {
    setScheduleList(null)
   }

  }

  const scheduleIdChange = (event) => {
    setScheduleId(event.target.value);
  
    setProjectSupportData({
      ...projectSupportData,
      schedule_id: event.target.value
    })

  };

  const contactPersonIdChange = (event) => {
    setScheduleId(event.target.value);
  
    setProjectSupportData({
      ...projectSupportData,
      bhtpa_contact_id: event.target.value
    })

  };

  const resetPageData = () => {
    //setProjectSupportData(initProjectScheduleExtend)
  }


  const submitData = (e) => {
    e.preventDefault();
    dispatch(createProjectSupportRequest(projectSupportData))

  }

  return (
    <div> {
      (<div className="body">
        <PageHeader
          HeaderText={`Create project support`}
          Breadcrumb={[
            { name: "Project support", navigate: "" },
            { name: "Create", navigate: "" },
          ]}
        />
        <h6></h6>
        <div className="row clearfix">
          <div className="col-lg-6 col-md-12">
            <div className="form-group ">


            <span>Select project</span>
            {/* <InputLabel id="demo-select-small form-group">Select project</InputLabel> */}
              <Select
                className="form-control"
                labelId="demo-select-small form-group"
                id="demo-select-small"
                value={projectId}
                label="Select project"
                onChange={projectIdChange}
              >
                <MenuItem className="form-group" value="Select project">
                  <em>Select project</em>
                </MenuItem>

                {success && data.data.map((item, index)=> {
                  return (<MenuItem value={item.id}>{item.project_name}</MenuItem>)
                })}
              </Select>
            </div>
            <div className="form-group ">

            <span>Select project schedule(Optional)</span>
            {/* <InputLabel id="demo-select-small form-group">Select project</InputLabel> */}
              <Select
                className="form-control"
                labelId="demo-select-small form-group"
                id="demo-select-small"
                value={scheduleId}
                label="Select project schedule"
                onChange={scheduleIdChange}
              >
                <MenuItem className="form-group" value="Select project">
                  <em>Select project schedule</em>
                </MenuItem>

                {scheduleList && scheduleList.map((item, index)=> {
                  return (<MenuItem value={item.id}>{item.schedule_name}</MenuItem>)
                })}
              </Select>
            </div>
            <div className="form-group">
              <span>Subject</span>
              <input
                className="form-control"
                placeholder=""
                name="subject"
                value={projectSupportData.subject}
                type="text"
                onChange={(e) => handleOnchange(e)}
              />
            </div>
            <div className="form-group">
              <span>Details</span>
              <input
                className="form-control"
                placeholder=""
                name="details"
                value={projectSupportData.details}
                type="text"
                onChange={(e) => handleOnchange(e)}
              />
            </div>

            <div className="form-group">
              <span>Support start date</span>
              <DatePicker dateFormat="yyyy-MM-dd" name="start_date" className="form-control" selected={project_start_date_picker}
                onChange={(date) => {
                  set_project_start_date_picker(date)
                  setProjectSupportData((state) => {
                    return {
                      ...state,
                      start_date: getDateOneFormatToAnother(date, "yyyy-MM-DD")
                    }
                  })
                }
                }
              />
            </div>

            <div className="form-group">
              <span>On site contact person</span>
              <input
                className="form-control"
                placeholder=""
                name="tenant_onsite_contact_name"
                value={projectSupportData.tenant_onsite_contact_name}
                type="text"
                onChange={(e) => handleOnchange(e)}
              />
            </div>

            <div className="form-group">
              <span>On site contact phone</span>
              <input
                className="form-control"
                placeholder=""
                name="tenant_onsite_contact_phone"
                value={projectSupportData.tenant_onsite_contact_phone}
                type="text"
                onChange={(e) => handleOnchange(e)}
              />
            </div>
           
          
            <div className="form-group ">

            <span>BHTPA Contact person(Optional)</span>
            {/* <InputLabel id="demo-select-small form-group">Select project</InputLabel> */}
              <Select
                className="form-control"
                labelId="demo-select-small form-group"
                id="demo-select-small"
                value={bhtpaContactId}
                label="Select contact person"
                onChange={contactPersonIdChange}
              >
                <MenuItem className="form-group" value="Select project">
                  <em>Select bhtpa contact person</em>
                </MenuItem>

                {contactList && (contactList.success && contactList.data.data.map((item, index)=> {
                  return (<MenuItem value={item.id}>{item.name}[{item.title}]</MenuItem>)
                }))}
              </Select>
            </div>
            
            <div className="form-group">

              {(projectSupportReducer.showMessage && !projectSupportReducer.isLoading) && <Message data={projectSupportReducer.updatedMessageDialog} />}

              {initProjectSupport && resetPageData()}
            </div>


            <div>

              <Button onClick={(e) => { submitData(e) }} variant="primary">
                {
                  projectSupportReducer.loading && <Spinner
                    as="span"
                    animation="grow"
                    size="sm"
                    role="status"
                    aria-hidden="true"
                  />
                }

                {projectSupportReducer.loading ? "Submitting..." : "Send request"}
              </Button>

              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;

              <Button onClick={(e) => { history.push("support-dashboard") }} variant="secondary">

                {"Back to support dashboard"}
              </Button>
              <br></br>
              <br></br>
              <br></br>

            </div>

          </div>


        </div>
      </div>)}


    </div>
  )
}
