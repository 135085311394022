import {    INIT_CREATE_PROJECT_SCHEDULE_EXTEND_REQUEST,
  CREATE_PROJECT_SCHEDULE_EXTEND_REQUEST_SUCCESS,
  CREATE_PROJECT_SCHEDULE_EXTEND_REQUEST_FAILURE,
  INIT_PROJECT_SCHEDULE_EXTEND_REQUEST_UPDATE,
  PROJECT_SCHEDULEUPDATE_EXTEND_REQUEST_SUCCESS,
  PROJECT_SCHEDULE_UPDATE_EXTEND_REQUEST_FAILURE,
  INIT_PROJECT_SCHEDULE_EXTEND_REQUEST_DELETE,
  PROJECT_SCHEDULE_DELETE_EXTEND_REQUEST_SUCCESS,
  PROJECT_SCHEDULE_DELETE_EXTEND_REQUEST_FAILURE,
 } from "../utils/constants"

const initialState = {
  isLoading: false,
  showMessage: false,
  updatedMessageDialog: {},
  error: null,
  response: {},
  success:false,
  updateSuccess:false,
  deleteSuccess:false,
};

const projectScheduleExtendRequestReducer = (state = initialState, action) => {

  switch (action.type) {

    case INIT_CREATE_PROJECT_SCHEDULE_EXTEND_REQUEST: {

      return {
        ...state,
        user: action.payload,
        isLoading: true
      };
    }
    case CREATE_PROJECT_SCHEDULE_EXTEND_REQUEST_SUCCESS: {

      const message = {
        show: true,
        title: "Project schedule extend request status",
        message:"Project schedule extend reqeust created successfully.",
        messageType: "success",
      }

      return {
        ...state,
        isLoading: false,
        response: action.payload,
        updatedMessageDialog: message,
        showMessage: true,
        success: true,


      }
    } 
    case CREATE_PROJECT_SCHEDULE_EXTEND_REQUEST_FAILURE: {

      const message = {
        show: true,
        title: "Project schedule extend request status",
        message: action.payload,
        messageType: "danger",
      }

      return {
        ...state,
        isLoading: false,
        error: action.payload,
        updatedMessageDialog: message,
        showMessage: true,

      }
    }

    case INIT_PROJECT_SCHEDULE_EXTEND_REQUEST_UPDATE: {

      return {
        ...state,
        user: action.payload,
        isLoading: true
      };
    }

    case PROJECT_SCHEDULEUPDATE_EXTEND_REQUEST_SUCCESS: {

      const message = {
        show: true,
        title: "Project schedule extend request update status",
        message:"Project schedule extend reqeust updated successfully.",
        messageType: "success",
      }

      return {
        ...state,
        isLoading: false,
        response: action.payload,
        updatedMessageDialog: message,
        showMessage: true,
        updateSuccess: true,

      }
    } 
    case PROJECT_SCHEDULE_UPDATE_EXTEND_REQUEST_FAILURE: {

      const message = {
        show: true,
        title: "Project schedule extend request update status",
        message: action.payload,
        messageType: "danger",
      }

      return {
        ...state,
        isLoading: false,
        error: action.payload,
        updatedMessageDialog: message,
        showMessage: true,

      }
    }

    case INIT_PROJECT_SCHEDULE_EXTEND_REQUEST_DELETE: {

      return {
        ...state,
        user: action.payload,
        isLoading: true
      };
    }

    case PROJECT_SCHEDULE_DELETE_EXTEND_REQUEST_SUCCESS: {

      const message = {
        show: true,
        title: "Project schedule extend reqeust delete status",
        message:"Project schedule extend request deleted successfully.",
        messageType: "success",
      }

      return {
        ...state,
        isLoading: false,
        response: action.payload,
        updatedMessageDialog: message,
        showMessage: true,
        deleteSuccess: true,

      }
    } 
    case PROJECT_SCHEDULE_DELETE_EXTEND_REQUEST_FAILURE: {

      const message = {
        show: true,
        title: "Project schedule extend request delete status",
        message: action.payload,
        messageType: "danger",
      }

      return {
        ...state,
        isLoading: false,
        error: action.payload,
        updatedMessageDialog: message,
        showMessage: true,

      }
    }

    default:
      return state;
  }
};


export default projectScheduleExtendRequestReducer;
