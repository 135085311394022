import React from "react";
import PageHeader from "../../components/PageHeader";
import {  ADMIN_USER} from "../../utils/constants"
import ProjectImplNameList from "../Dashbord/ProjectImplNameList";

export default function ProjectImplNameDashboard() {


  return (
    <div className="dashboard-container">
      <PageHeader
        HeaderText="Project name"
        Breadcrumb={[{ name: "project-implementation-name" }]}
      />
      
      <div className={`dashboard-container-summary`}>
        <ProjectImplNameList user_type={ADMIN_USER} />
      </div>
    </div>
  )
}


