import {    INIT_CREATE_PROJECT_SUPPORT_MESSAGE_REQUEST,
  CREATE_PROJECT_SUPPORT_MESSAGE_SUCCESS,
  CREATE_PROJECT_SUPPORT_MESSAGE_FAILURE
 } from "../utils/constants"

const initialState = {
  isLoading: false,
  showMessage: false,
  updatedMessageDialog: {},
  error: null,
  response: {},
  success:false,
  updateSuccess:false,
  deleteSuccess:false,
};

const projectSupportMessageReducer = (state = initialState, action) => {

  switch (action.type) {

    case INIT_CREATE_PROJECT_SUPPORT_MESSAGE_REQUEST: {

      return {
        ...state,
        user: action.payload,
        isLoading: true
      };
    }
    case CREATE_PROJECT_SUPPORT_MESSAGE_SUCCESS: {

      const message = {
        show: true,
        title: "Project support message status",
        message:"Project support message created successfully.",
        messageType: "success",
      }

      return {
        ...state,
        isLoading: false,
        response: action.payload,
        updatedMessageDialog: message,
        showMessage: true,
        success: true,


      }
    } 
    case CREATE_PROJECT_SUPPORT_MESSAGE_FAILURE: {

      const message = {
        show: true,
        title: "Project support message status",
        message: action.payload,
        messageType: "danger",
      }

      return {
        ...state,
        isLoading: false,
        error: action.payload,
        updatedMessageDialog: message,
        showMessage: true,

      }
    }

    // case INIT_PROJECT_SUPPORT_REQUEST_UPDATE: {

    //   return {
    //     ...state,
    //     user: action.payload,
    //     isLoading: true
    //   };
    // }

    // case PROJECT_SUPPORT_UPDATE_REQUEST_SUCCESS: {

    //   const message = {
    //     show: true,
    //     title: "Project support update status",
    //     message:"Project support updated successfully.",
    //     messageType: "success",
    //   }

    //   return {
    //     ...state,
    //     isLoading: false,
    //     response: action.payload,
    //     updatedMessageDialog: message,
    //     showMessage: true,
    //     updateSuccess: true,

    //   }
    // } 
    // case PROJECT_SUPPORT_UPDATE_REQUEST_FAILURE: {

    //   const message = {
    //     show: true,
    //     title: "Project support update status",
    //     message: action.payload,
    //     messageType: "danger",
    //   }

    //   return {
    //     ...state,
    //     isLoading: false,
    //     error: action.payload,
    //     updatedMessageDialog: message,
    //     showMessage: true,

    //   }
    // }

    // case INIT_PROJECT_SUPPORT_DELETE: {

    //   return {
    //     ...state,
    //     user: action.payload,
    //     isLoading: true
    //   };
    // }

    // case PROJECT_SUPPORT_DELETE_SUCCESS: {

    //   const message = {
    //     show: true,
    //     title: "Project support delete status",
    //     message:"Project support deleted successfully.",
    //     messageType: "success",
    //   }

    //   return {
    //     ...state,
    //     isLoading: false,
    //     response: action.payload,
    //     updatedMessageDialog: message,
    //     showMessage: true,
    //     deleteSuccess: true,

    //   }
    // } 
    // case PROJECT_SUPPORT_DELETE_FAILURE: {

    //   const message = {
    //     show: true,
    //     title: "Project support delete status",
    //     message: action.payload,
    //     messageType: "danger",
    //   }

    //   return {
    //     ...state,
    //     isLoading: false,
    //     error: action.payload,
    //     updatedMessageDialog: message,
    //     showMessage: true,

    //   }
    // }

    default:
      return state;
  }
};


export default projectSupportMessageReducer;
