import {    
  INIT_CREATE_PROJECT_PACKAGE,
CREATE_PROJECT_PACKAGE_SUCCESS, 
CREATE_PROJECT_PACKAGE_FAILURE,
INIT_DELETE_PROJECT_IPACKAGE_REQUEST,
DELETE_PROJECT_PACKAGE_SUCCESS,
DELETE_PROJECT_PACKAGE_FAILURE
 } from "../utils/constants"

const initialState = {
  isLoading: false,
  showMessage: false,
  updatedMessageDialog: {},
  error: null,
  response: {},
  success:false,
  updateSuccess:false,
  deleteSuccess:false,
};

const projectPackageReducer = (state = initialState, action) => {

  switch (action.type) {

    case INIT_CREATE_PROJECT_PACKAGE: {

      return {
        ...state,
        user: action.payload,
        isLoading: true
      };
    }
    case CREATE_PROJECT_PACKAGE_SUCCESS: {

      const message = {
        show: true,
        title: "Package status",
        message:action.payload.message,
        messageType: "success",
      }

      return {
        ...state,
        isLoading: false,
        response: action.payload,
        updatedMessageDialog: message,
        showMessage: true,
        success: true,


      }
    } 
    case CREATE_PROJECT_PACKAGE_FAILURE: {

      const message = {
        show: true,
        title: "Package status",
        message: action.payload,
        messageType: "danger",
      }

      return {
        ...state,
        isLoading: false,
        error: action.payload,
        updatedMessageDialog: message,
        showMessage: true,

      }
    }

    

    case INIT_DELETE_PROJECT_IPACKAGE_REQUEST: {

      return {
        ...state,
        user: action.payload,
        isLoading: true
      };
    }

    case DELETE_PROJECT_PACKAGE_SUCCESS: {

      const message = {
        show: true,
        title: "Package delete status",
        message:"Package deleted successfully.",
        messageType: "success",
      }

      return {
        ...state,
        isLoading: false,
        response: action.payload,
        updatedMessageDialog: message,
        showMessage: true,
        deleteSuccess: true,

      }
    } 
    case DELETE_PROJECT_PACKAGE_FAILURE: {

      const message = {
        show: true,
        title: "Package delete status",
        message: action.payload,
        messageType: "danger",
      }

      return {
        ...state,
        isLoading: false,
        error: action.payload,
        updatedMessageDialog: message,
        showMessage: true,

      }
    }

    default:
      return state;
  }
};


export default projectPackageReducer;
