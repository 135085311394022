import React, { useEffect, useState } from 'react';
import UserRow from './UserRow';
import { getAllUsersURL } from '../../utils/allurls';
import { useFetch, useFetchDataFrom } from '../../customHooks/fetchData';
import { PaginatedItems } from '../../components/Paginate/Paginate';

export default function UerList(props) {
  const { user_type } = props;
  console.log('user_type', user_type);
  const [queryObj, updateQueryObject] = useState({ page: 0, per_page: 20 });
  //   const { loading, data} = useFetch(getAllUsersURL(user_type));

  const { loading, data, disPatcher } = useFetchDataFrom(getAllUsersURL(user_type, queryObj.page, queryObj.per_page));

  useEffect(() => {
    disPatcher(getAllUsersURL(user_type, queryObj.page, queryObj.per_page));
  }, [queryObj, user_type, disPatcher]);

  return (
    <>
      <div className='body table-responsive'>
        <table className='table'>
          <thead>
            <tr>
              <th>Serial No.</th>
              <th>Name</th>
              <th>Email</th>
              <th>Short details</th>
              <th>Create date</th>
              <th>Account Type</th>
            </tr>
          </thead>
          <tbody>
            {data.data &&
              data.data.map((user) => {
                return <UserRow user={user} />;
              })}
          </tbody>
        </table>
      </div>
      <div className='my-4'>
        <PaginatedItems
          itemsPerPage={queryObj.per_page}
          onClick={(parm) => updateQueryObject({ ...queryObj, page: parm })}
          totalItems={data.pagination?.total}
        />
      </div>
    </>
  );
}
