import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router"
import PageHeader from "../../components/PageHeader";
import { ADMIN_USER } from "../../utils/constants";
import path from 'path'
import Button from 'react-bootstrap/Button'
import { useFetch } from "../../customHooks/fetchData"
import { getNoticeboardListURL } from "../../utils/allurls"
import NoticeboardMessage from "./NoticeboardMessage";
import { userType, userId } from "../../utils/userUtils"


export default function NoticeboardDashbord() {

  const dispatch = useDispatch();
  const history = useHistory();

  //console.log(path.resolve(__dirname, './__test__'));

  const requestURL = userType() === ADMIN_USER?getNoticeboardListURL(""):getNoticeboardListURL(userId())

  const noticeBoardData = useFetch(requestURL)




  return (
    <div className="dashboard-container">
      <PageHeader
        HeaderText="Notice board"
        Breadcrumb={[{ name: "notice-board" }]}


      />
      <div className="align-space-between-side margin-top-bottom">
        <div></div>
        <div>
          {(userType() === ADMIN_USER) && <Button onClick={(e) => { history.push("create-notice-board") }} variant="primary">
            {"Create notice board"}
          </Button>}
        </div>

      </div>
      <div className="verticalDisplay">

        {noticeBoardData.success && noticeBoardData.data.data.map((item, i) => {
          return <NoticeboardMessage
            noticeBoardObject={item}
          />
        })}

      </div>

    </div>
  )
}


