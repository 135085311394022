import React from "react";
import * as echarts from "echarts";
import {
  visitorsSparcalOption,
  visitsSparcalOption,
} from "../Data/DashbordData";
import { dashboardURLBasedOnUserType } from "../utils/GeneralUtils";



class PageHeader extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      toggleMenu: true,
      selectedBtn: 1
    };
    this.onToggleMenu();
  }
  componentDidMount() {
    this.chartPlace();
    this.chartPlace1();
    this.onToggleMenu();
  }
  chartPlace = () => {
    var chartDom = document.getElementById("visitorsSparcalChart");
    var visitorsSparcalChart = echarts.init(chartDom);
    var option;
    option = visitorsSparcalOption;

    option && visitorsSparcalChart.setOption(option);
  };

  chartPlace1 = () => {
    var chartDom = document.getElementById("visitsSparcalChart");
    var visitsSparcalChart = echarts.init(chartDom);
    var option;
    option = visitsSparcalOption;

    option && visitsSparcalChart.setOption(option);
  };

  onToggleMenu = async () => {
    await this.setState({
      toggleMenu: !this.state.toggleMenu,
    });
    const { toggleMenu } = this.state;
    if (!toggleMenu) {
      document.body.classList.remove("layout-fullwidth");
    } else {
      document.body.classList.add("layout-fullwidth");
    }
  };
  render() {
    const { HeaderText, Breadcrumb, handleSite } = this.props;

    
    return (
      <div className="block-header">
        <div className="row">
          <div className="col-xl-4 col-lg-4 col-md-4 col-sm-4">
            <h2>
              <a
                href="#!"
                className="btn btn-xs btn-link btn-toggle-fullwidth"
                onClick={(e) => {
                  e.preventDefault();
                  this.onToggleMenu();
                }}
              >
                <i
                  className={
                    !this.state.toggleMenu
                      ? `fa fa-arrow-left`
                      : "fa fa-bars"
                  }
                ></i>
              </a>{" "}
              {HeaderText}
            </h2>
            <ul className="breadcrumb">
              <li className="breadcrumb-item">
                <a href={dashboardURLBasedOnUserType()}>
                  <i className="icon-home"></i>
                </a>
              </li>
              {Breadcrumb.map((item, index) => {
                return (
                  <li
                    key={item.name + index}
                    className="breadcrumb-item active"
                  >
                    <a href={item.navigate ? item.navigate : null}>
                      {item.name}
                    </a>
                  </li>
                );
              })}
            </ul>
          </div>

          <div className={`col-xl-4 col-lg-4 col-md-4 col-sm-4 text-right`} >
          {
          //handleSite
          // when multi site is active this handle site will be replaced with false
          }
          { false && (<div className="flex-middle">
            <select onChange={(val) => handleSite(val.target.value)} className="custom-select" id="inputGroupSelect01">
              <option value="0">Choose hi-tech park...</option>
              <option value="1" selected>Kaliakoir hi-tech park</option>
              <option value="2">Sylhet hi-tech park</option>
              <option value="3">Jessore hi-tech park</option>
              <option value="4">Chattogram hi-tech park</option>
            </select>
          </div>)}
            
          </div>


          <div className={`col-xl-4 col-lg-4 col-md-4 col-sm-4 text-right`} >
            <div className="inlineblock text-center m-r-15 m-l-15 hidden-sm">
              <div
                id="visitorsSparcalChart"
                className="sparkline text-left"
                style={{ width: "12vh", height: 25 }}
              ></div>
              <span>Visitors</span>
            </div>
            <div className="inlineblock text-center m-r-15 m-l-15 hidden-sm">
              <div
                id="visitsSparcalChart"
                className="sparkline text-left"
                style={{ width: "12vh", height: 25 }}
              ></div>
              <span>Visits</span>
            </div>
          </div>

        </div>
      </div>
    );
  }
}

export default PageHeader;
