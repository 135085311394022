import React from 'react'
import MAN_AVATAR from "../../assets/images/profile_avatar.png"
import { dateStringFull } from "../../utils/DateUtils"

export default function Message(props) {

    const {message, time, is_mine, is_admin} = props

    // <li className="clearfix">
    //                 <div className="message-data">
    //                   <span className="message-data-time">10:15 AM, Today</span>
    //                 </div>
    //                 <div className="message my-message">
    //                   Project has been already finished and I have results to
    //                   show you.
    //                 </div>
    //               </li>

    const textRight = is_mine ? "text-right" : ""
    const floatRight = is_mine ? "other-message float-right" : "my-message"

    return (
        <>
            <li className="clearfix">
                <div className={`message-data ${textRight}`}>
                    <span className="message-data-time">{dateStringFull(time)}</span>
                    {false && <img alt="avatar" src={MAN_AVATAR} />}
                </div>
                <div className={`message ${floatRight}`}>
                    {" "}
                    {message}{" "}
                </div>
            </li>
        </>
    )
}
