import React from 'react';
import './MakeWrapper.css';

export default function MakeWrapper({ children, label }) {
  return (
    <div className='make-wrapper'>
      <div className='header'>{label}</div>
      <div className='content'>{children}</div>
    </div>
  );
}
