import axios from "axios";
import { BASE_HEADERS } from "../utils/baseValues";
import { createNewUserURL } from "../utils/allurls";
import {
   REQUEST_CREATE_ACCOUNT,
   REQUEST_CREATE_ACCOUNT_SUCCESS,
   REQUEST_CREATE_ACCOUNT_FAILED
} from "../utils/constants";

export const createNewUser = (userData) => async (dispatch) => {

   dispatch({ type: REQUEST_CREATE_ACCOUNT, payload: userData })

   try {

      const headers = BASE_HEADERS()
      const response = await axios.post(createNewUserURL, userData, { headers });

      console.log("create response ", response)
      if (response.status === 201) {

         dispatch({ type: REQUEST_CREATE_ACCOUNT_SUCCESS, payload: response.data })
      } else {
         dispatch({ type: REQUEST_CREATE_ACCOUNT_FAILED, payload: response.error })
      }

   } catch (error) {
      console.log("create error response ", error.response.data.error)
      dispatch({ type: REQUEST_CREATE_ACCOUNT_FAILED, payload: error.response.data.error })
      
   }
}