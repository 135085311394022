import axios from "axios";
import { BASE_HEADERS } from "../utils/baseValues";
import { createProjectScheduleExtendRequestURL, updateProjectScheduleExtendRequestStatusURL,deleteProjectScheduleExtendRequestURL } from "../utils/allurls";
import {
   INIT_CREATE_PROJECT_SCHEDULE_EXTEND_REQUEST,
   CREATE_PROJECT_SCHEDULE_EXTEND_REQUEST_SUCCESS,
   CREATE_PROJECT_SCHEDULE_EXTEND_REQUEST_FAILURE,
   INIT_PROJECT_SCHEDULE_EXTEND_REQUEST_UPDATE,
   PROJECT_SCHEDULEUPDATE_EXTEND_REQUEST_SUCCESS,
   PROJECT_SCHEDULE_UPDATE_EXTEND_REQUEST_FAILURE,
   INIT_PROJECT_SCHEDULE_EXTEND_REQUEST_DELETE,
   PROJECT_SCHEDULE_DELETE_EXTEND_REQUEST_SUCCESS,
   PROJECT_SCHEDULE_DELETE_EXTEND_REQUEST_FAILURE,
} from "../utils/constants";

export const createProjectScheduleExtendRequest = (data) => async (dispatch) => {

   dispatch({ type: INIT_CREATE_PROJECT_SCHEDULE_EXTEND_REQUEST, payload: data })

   try {

      const headers = BASE_HEADERS()
      const response = await axios.post(createProjectScheduleExtendRequestURL, data, { headers });
      console.log("create response", response)

      if (response.status === 201) {

         dispatch({ type: CREATE_PROJECT_SCHEDULE_EXTEND_REQUEST_SUCCESS, payload: response.data })
      } else {
         dispatch({ type: CREATE_PROJECT_SCHEDULE_EXTEND_REQUEST_FAILURE, payload: response.error })
      }

   } catch (error) {

      dispatch({ type: CREATE_PROJECT_SCHEDULE_EXTEND_REQUEST_FAILURE, payload: error.response.data.error })
   }
}


export const updateProjectScheduleExtendRequestStatus = (data) => async (dispatch) => {

   dispatch({ type: INIT_PROJECT_SCHEDULE_EXTEND_REQUEST_UPDATE, payload: data })

   try {

      const headers = BASE_HEADERS()
      const response = await axios.post(updateProjectScheduleExtendRequestStatusURL, data, { headers });
      console.log("update response", response)

      if (response.status === 200) {

         dispatch({ type: PROJECT_SCHEDULEUPDATE_EXTEND_REQUEST_SUCCESS, payload: response.data })
      } else {
         dispatch({ type: PROJECT_SCHEDULE_UPDATE_EXTEND_REQUEST_FAILURE, payload: response.error })
      }

   } catch (error) {

      dispatch({ type: PROJECT_SCHEDULE_UPDATE_EXTEND_REQUEST_FAILURE, payload: error.response.data.error })
   }
}


export const deleteProjectScheduleExtendRequest = (extendRequestId) => async (dispatch) => {


   dispatch({ type: INIT_PROJECT_SCHEDULE_EXTEND_REQUEST_DELETE, payload: extendRequestId })

   try {

      const headers = BASE_HEADERS()
      const response = await axios.delete(deleteProjectScheduleExtendRequestURL(extendRequestId), { headers });
      console.log("delete schedule extend reqeust response", response)

      if (response.status === 202) {

         dispatch({ type: PROJECT_SCHEDULE_DELETE_EXTEND_REQUEST_SUCCESS, payload: response.data })
      } else {
         dispatch({ type: PROJECT_SCHEDULE_DELETE_EXTEND_REQUEST_FAILURE, payload: response.error })
      }

   } catch (error) {

      dispatch({ type: PROJECT_SCHEDULE_DELETE_EXTEND_REQUEST_FAILURE, payload: error.response.data.error })
   }
}