import React from 'react'

interface Props {
    status: string
}

export default function StatusInfo(props: Props) {

    const { status } = props

    const getProjectSupportStatus = (status: string): JSX.Element => {

        var statusView
      
        if (status === 'resolved') {
            statusView = (<span className="badge badge-success bg-success text-white">
                {status}
            </span>)
      
        } else if (status === 'closed') {
            statusView = (<span className="badge badge-danger bg-info text-white">
                {status}
            </span>)
        } else if (status === 'open') {
            statusView = (<span className="badge badge-danger bg-warning text-white">
                {status}
            </span>)
        } else {
            statusView = (<span className="badge badge-danger bg-warning text-white">
                {status}
            </span>)
        }
      
        return statusView
      }

  return (
    <>{getProjectSupportStatus(status)}</>
  )
}
