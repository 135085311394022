import React, { useState } from 'react';
import { useHistory } from 'react-router';
import { TENANT_USER, ADMIN_USER } from '../../utils/constants';
import Button from 'react-bootstrap/Button';
import { useFetch } from '../../customHooks/fetchData';
import { getRentListURL } from '../../utils/allurls';
import { userId } from '../../utils/userUtils';
import { useSelector, useDispatch } from 'react-redux';
import { updateRentAttribute, deleteRent } from '../../actions/rentAction';
import swal from 'sweetalert';
import { dateStringFull } from '../../utils/DateUtils';
import CustomStatusInfo from '../../utils/CustomStatusInfo';
import { BASE_HEADERS_FILE_TYPE } from '../../utils/baseValues';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import axios from 'axios';
import { updateRentURL } from '../../utils/allurls';

const initLoaderMessage = {
  isLoading: false,
  message: '',
};

var rentIdSelected = '';

export default function RentList(props) {
  const history = useHistory();
  const dispatch = useDispatch();
  const rentReducer = useSelector((state) => state.rentReducer);
  const [showModal, setShowModal] = useState(false);
  const [noteValue, setNoteValue] = useState('');
  const [file, setFile] = useState();
  const [loaderMessage, setLoaderMessage] = useState(initLoaderMessage);

  var requestURL;
  if (props.user_type === TENANT_USER) {
    requestURL = getRentListURL(userId(), '');
  } else if (props.user_type === ADMIN_USER) {
    requestURL = getRentListURL('', '');
  }

  const { loading, data, success } = useFetch(requestURL);
  console.log('Rentlist', data);

  const handleClose = () => setShowModal(false);
  const handleShow = (rentId) => {
    rentIdSelected = rentId;
    setShowModal(true);
  };
  function handleChange(event) {
    setFile(event.target.files[0]);
  }

  const deleteRentRequest = (tempRentId) => {
    dispatch(deleteRent(tempRentId));
  };

  const projectUpdateSuccess = (success) => {
    if (success) {
      swal('Rent update status', 'Updated succesfully', 'success').then(function () {
        window.location.reload(false);
      });
    }
  };

  const onChangeField = (event) => {
    event.preventDefault();
    setNoteValue(event.target.value);
  };

  const submitRentUpdate = (rentId) => {
    setLoaderMessage({
      isLoading: true,
      message: '',
    });

    const url = updateRentURL;
    const formData = new FormData();
    formData.append('file', file);
    formData.append('fileName', file.name);
    formData.append('id', rentId);
    formData.append('payment_status', 'rent_paid_waiting_for_approval');
    formData.append('note', noteValue);

    axios.post(url, formData, BASE_HEADERS_FILE_TYPE()).then((response) => {
      console.log(response.data);
      setShowModal(false);
      setLoaderMessage({
        isLoading: false,
        message: 'Rent updated successfully',
      });
      swal('Rent update status', 'Updated succesfully', 'success').then(function () {
        window.location.reload(false);
      });
    });
  };

  const projectDeleteSuccess = (success) => {
    if (success) {
      swal('Rent delete status', 'Rent deleted succesfully', 'success').then(function () {
        window.location.reload(false);
      });
    }
  };

  const checkDateAndReturn = (dValue) => {
    console.log('dValue: ', dValue);

    const dValueInt = parseInt(dValue);

    if (dValueInt > 0) {
      dateStringFull(dValueInt);
    }
    return '--';
  };

  return (
    <>
      <div className='col-lg-12'>
        <div className='card product_item_list product-order-list'>
          <div className='header align-space-between-side'>
            <h2>Rents</h2>
          </div>
          <div className='body'>
            <div className='table-responsive'>
              <table className='table table-hover m-b-0'>
                <thead className='thead-dark'>
                  <tr>
                    <th>ID</th>
                    <th>Investor name</th>
                    <th>Year</th>

                    <th data-breakpoints='sm xs'>Issue date</th>

                    <th data-breakpoints='xs'>Paid date</th>
                    <th data-breakpoints='sm xs md'>Rent amount</th>
                    <th data-breakpoints='sm xs md'>Status</th>
                    <th data-breakpoints='xs md'>Action</th>

                    {props.user_type === ADMIN_USER && <th data-breakpoints='xs md'>View</th>}
                  </tr>
                </thead>
                <tbody>
                  {loading && 'Loading...'}
                  {success &&
                    data.data.map((project, index) => (
                      <tr key={index}>
                        <td>{project.id}</td>
                        <td>{project.project_name}</td>
                        <td>
                          <div class='request-top' style={{ whiteSpace: 'pre-wrap', overflowWrap: 'break-word' }}>
                            <p>{project.year}</p>
                          </div>
                        </td>

                        <td>{dateStringFull(project.invoice_created_date)}</td>

                        <td>{checkDateAndReturn(project.payment_date)}</td>
                        <td>Tk {project.total_amount}</td>
                        <td>
                          <CustomStatusInfo status={project.payment_status} user_type={props.user_type} />
                        </td>
                        {props.user_type === TENANT_USER && (
                          <td>
                            <div>
                              {project.payment_status === 'rent_issued' && (
                                <Button
                                  onClick={() => {
                                    handleShow(project.id);
                                  }}
                                >
                                  {'Pay'}
                                </Button>
                              )}
                            </div>

                            {/* <a className="btn btn-outline-secondary mr-1">
                                           <i className="icon-pencil"></i>
                                       </a> */}
                          </td>
                        )}

                        {props.user_type === ADMIN_USER && (
                          <td>
                            <div class='request-top verticalDisplay' style={{ whiteSpace: 'pre-wrap', overflowWrap: 'break-word' }}>
                              {project.payment_status === 'rent_paid_waiting_for_approval' && (
                                <Button
                                  variant='primary'
                                  onClick={() => {
                                    const jsonBody = {
                                      id: project.id,
                                      payment_status: 'rent_paid_and_approved',
                                    };

                                    dispatch(updateRentAttribute(jsonBody));
                                  }}
                                >
                                  {'Approve'}
                                </Button>
                              )}

                              <a className='btn btn-outline-secondary'>
                                <i
                                  className='icon-trash'
                                  onClick={() => {
                                    deleteRentRequest(project.id);
                                  }}
                                ></i>
                              </a>
                            </div>
                          </td>
                        )}

                        {props.user_type === ADMIN_USER && (
                          <td
                            onClick={() => {
                              var imageURL = 'http://localhost:5050/static/' + project.payment_slip_url;

                              window.open(imageURL, '_blank');
                            }}
                            data-breakpoints='xs md'
                          >
                            View
                          </td>
                        )}

                        {projectUpdateSuccess(rentReducer.updateSuccess)}
                        {projectDeleteSuccess(rentReducer.deleteSuccess)}
                      </tr>
                    ))}
                </tbody>
              </table>
              <Modal show={showModal} onHide={handleClose}>
                <Modal.Header closeButton>
                  <Modal.Title>Pay rent</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  <Form>
                    <Form.Group className='mb-3' controlId='exampleForm.ControlInput1'>
                      <Form.Label>Upload pay slip</Form.Label>
                      <div className='form-group'>
                        <input type='file' onChange={handleChange} className='form-control-file' id='exampleInputFile' aria-describedby='fileHelp' />
                        {/* <small id="fileHelp" className="form-text text-muted">
                    Proof of payment document
                  </small> */}
                      </div>
                    </Form.Group>
                    <Form.Group className='mb-3' controlId='exampleForm.ControlTextarea1'>
                      <Form.Label>Note (Optional)</Form.Label>
                      <Form.Control onChange={onChangeField} as='textarea' rows={3} />
                    </Form.Group>
                  </Form>
                </Modal.Body>
                <Modal.Footer>
                  <Button variant='secondary' onClick={handleClose}>
                    Close
                  </Button>
                  <Button
                    variant='primary'
                    onClick={() => {
                      submitRentUpdate(rentIdSelected);
                    }}
                  >
                    Save Changes
                  </Button>
                </Modal.Footer>
              </Modal>
            </div>
          </div>
        </div>
      </div>{' '}
    </>
  );
}
