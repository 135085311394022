import React from 'react'
import { useHistory, useLocation } from "react-router"
import Button from 'react-bootstrap/Button'
import { useFetch } from "../../customHooks/fetchData"
import { getPackageListByProjectIdURL } from "../../utils/allurls"
import { useSelector, useDispatch } from "react-redux";
import { deleteProjectPackage } from "../../actions/projectPackageAction"
import swal from 'sweetalert';
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';

export default function ProjectPackageRow(props) {

    const history = useHistory()
    const location = useLocation()
    const dispatch = useDispatch()
    const projectPackageReducer = useSelector(state => state.projectPackageReducer)
    let projectObj
    if (props.project) {
        projectObj = props.project
    } else {
        projectObj = location.state
    }

    var requestURL;
    requestURL = getPackageListByProjectIdURL(projectObj.id, 1)

    const { loading, data, success } = useFetch(requestURL)

    const projectDeleteSuccess = (success) => {

        if (success) {
            swal("Package status", "Package deleted succesfully", "success")
                .then(function () {
                    window.location.reload(false)
                });
        }
    }

    const deleteRequest = async (packageId) => {
        dispatch(deleteProjectPackage(packageId))
    }


    return (
        <><div className="col-lg-12">
            <div className="card product_item_list product-order-list">
                <div className="header align-space-between-side">
                    <span>{`Package list of "${projectObj.name}"`}</span>
                    <span>* All figure in lakh taka</span>
                    {<Button onClick={(e) => {

                        history.push({ pathname: "save-project-package", state: { packageDetails: null, project: projectObj } })

                    }} variant="primary">

                        {"Add package"}
                    </Button>}
                </div>
                <div className="body">
                    <div className="table-responsive">
                        <table className="table table-hover m-b-0">
                            <thead className="thead-dark">
                                <tr>
                                    <th data-breakpoints="sm xs">Sl.</th>
                                    <th>Package name</th>
                                    <th>Contract value</th>
                                    <th>Contract date</th>
                                    <th>Contract expire</th>
                                    <th>Financial progress</th>
                                    <th>Actual progress</th>
                                    <th></th>
                                    <th></th>




                                </tr>
                            </thead>
                            <tbody>
                                {loading && "Loading..."}
                                {success && (data.data).map((packageObj, index) => (<tr key={index}>
                                    <td>
                                        <div className="request-top" style={{ whiteSpace: 'pre-wrap', overflowWrap: 'break-word' }}>
                                            <p>{(index + 1)}</p>
                                        </div>
                                    </td>
                                    <td>
                                        <div className="request-top" style={{ whiteSpace: 'pre-wrap', overflowWrap: 'break-word' }}>
                                            <p>{packageObj.package_name}</p>
                                        </div>

                                    </td>
                                    <td>{packageObj.contract_value}</td>
                                    <td>{packageObj.contract_date}</td>
                                    <td>{packageObj.contract_expire_date}</td>
                                    <td>{packageObj.financial_progress}%</td>
                                    <td>{packageObj.actual_progress}%</td>


                                    <td>
                                        <Button variant="primary" onClick={() => {

                                            history.push({ pathname: "save-project-package", state: { packageDetails: packageObj, project: projectObj } })


                                        }} >{"Edit"}</Button>

                                    </td>
                                    <td>
                                        <Button variant="secondary" onClick={() => {


                                            swal({
                                                title: "Are you sure?",
                                                text: "Once deleted, you will not be able to recover this package item!",
                                                icon: "warning",
                                                buttons: true,
                                                dangerMode: true,
                                            })
                                                .then((willDelete) => {
                                                    if (willDelete) {

                                                        deleteRequest(packageObj.id)

                                                    }
                                                });




                                        }} >{"Delete"}</Button>

                                    </td>
                                    {projectDeleteSuccess(projectPackageReducer.deleteSuccess)}
                                </tr>))}


                            </tbody>
                        </table>
                        {(success && (data.data).length === 0) &&  <Alert style={{margin: "10px",}} severity="info">
                            <AlertTitle>Information</AlertTitle>
                            No package is created yet!
                        </Alert>}
                    </div>
                </div>
            </div>
        </div> </>
    )
}
