const areaColorStyle = ["#fac091", "#a092b0", "#92cddc", "#95b3d7", "#a092b0", "#92cddc"]
const itemColorStyle = ["#4aacc5", "#604a7b", "#4aacc5", "#4f81bc", "#604a7b", "#4aacc5"]
const getRandomArrayInteger = () => {

    const randomArray = []

    for (let i = 0; i < 6; i++) {
        var randomNumber = Math.floor(Math.random() * (5 - 0 + 1)) + 0;
        randomArray.push(randomNumber)
    }

    return randomArray

}

export const getRandomsparklineData = () => {

    var randomIndex = Math.floor(Math.random() * (5 - 0 + 1)) + 0;

    return {
        type: "line",
        data: getRandomArrayInteger(),
        areaStyle: {
            color: areaColorStyle[randomIndex],
        },
        itemStyle: {
            color: itemColorStyle[randomIndex],
        },
        symbolSize: 1,
    }

}
export const dashboardItems = [
    {
        id: 1, name: "Tenants", value: "20", valueType: "", bgColor: "light-blue",
        sparklineData: getRandomsparklineData()
    },
    {
        id: 2, name: "Operations", value: "15", valueType: "", bgColor: "green-bg",
        sparklineData: getRandomsparklineData()
    },
    {
        id: 3, name: "Rent completed", value: "12", valueType: "", bgColor: "dark-blue-bg",
        sparklineData: getRandomsparklineData()
    }, {
        id: 4, name: "Number of Sites", value: "4", valueType: "", bgColor: "yellow-bg",
        sparklineData: getRandomsparklineData()
    }, {
        id: 5, name: "Number of Scopes", value: "104", valueType: "", bgColor: "green-bg",
        sparklineData: getRandomsparklineData()
    },
    {
        id: 6, name: "Work progress", value: "50", valueType: "%", bgColor: "yellow-bg",
        sparklineData: getRandomsparklineData()
    }, {
        id: 7, name: "Service requested tickets", value: "12", valueType: "", bgColor: "green-bg",
        sparklineData: getRandomsparklineData()
    }, {
        id: 8, name: "Exports", value: "150 ", valueType: "Million", bgColor: "yellow-bg",
        sparklineData: getRandomsparklineData()
    }, {
        id: 9, name: "Jobs", value: "1000", valueType: "", bgColor: "green-bg",
        sparklineData: getRandomsparklineData()
    },
    {
        id: 10, name: "In Schedule", value: "10", valueType: "", bgColor: "dark-blue-bg",
        sparklineData: getRandomsparklineData()
    },
    {
        id: 11, name: "Behind Schedule", value: "5", valueType: "", bgColor: "dark-gray-bg",
        sparklineData: getRandomsparklineData()
    },
    {
        id: 12, name: "In Active", value: "86", valueType: "%", bgColor: "red-bg",
        sparklineData: getRandomsparklineData()
    },

]

export const tenantUserDashboardItems = [
    
    {
        id: 1, name: "Project", value: "1", valueType: "", bgColor: "green-bg",
        sparklineData: getRandomsparklineData()
    },
    {
        id: 2, name: "Rent completed", value: "Year 2021", valueType: "", bgColor: "dark-blue-bg",
        sparklineData: getRandomsparklineData()
    }, {
        id: 3, name: "Total Number of Schedule", value: "5", valueType: "", bgColor: "yellow-bg",
        sparklineData: getRandomsparklineData()
    }, {
        id: 4, name: "In progress", value: "3", valueType: "", bgColor: "green-bg",
        sparklineData: getRandomsparklineData()
    },
    {
        id: 5, name: "Not started yet", value: "1", valueType: "%", bgColor: "yellow-bg",
        sparklineData: getRandomsparklineData()
    }, {
        id: 6, name: "Behind schedule", value: "1", valueType: "", bgColor: "green-bg",
        sparklineData: getRandomsparklineData()
    }, {
        id: 7, name: "Total jobs created", value: "10", valueType: "", bgColor: "yellow-bg",
        sparklineData: getRandomsparklineData()
    }, {
        id: 8, name: "Total Export", value: "0 ", valueType: "Million", bgColor: "yellow-bg",
        sparklineData: getRandomsparklineData()
    }, 
    

]

export const rentDashboardItems = [
    {
        id: 1, name: "Cleared rents at current year", value: "25", valueType: "", bgColor: "light-blue",
        sparklineData: getRandomsparklineData()
    },
    {
        id: 2, name: "Pending rents at current year", value: "15", valueType: "", bgColor: "green-bg",
        sparklineData: getRandomsparklineData()
    },
    {
        id: 3, name: "Total collectable rents at current year", value: "40", valueType: "", bgColor: "dark-blue-bg",
        sparklineData: getRandomsparklineData()
    }

]

