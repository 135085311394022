import React, { useState} from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import MAN_AVATAR from "../../assets/images/profile_avatar.png"
import Message from "./Message";
import { useFetch } from "../../customHooks/fetchData"
import { userType, userId } from "../../utils/userUtils"
import { getProjectSupportMessageListURL } from "../../utils/allurls"
import { TENANT_USER } from "../../utils/constants"
import { createProjectSupportMessageRequest } from "../../actions/projectSupportMessageAction";
import { useSelector, useDispatch } from "react-redux";

export default function ChatSupportTexting(props) {


  const { supportObject} = props;

  const initSupportMessage = {
    project_support_id: supportObject.id,
    sender_id: userId(),
    sender_type: userType(),
    message: ""
  }

  
  const dispatch = useDispatch()
  const requestURL = getProjectSupportMessageListURL(supportObject.id, 1)
  const floatRight = userType() ===TENANT_USER? "float-right": ""
  const [supportMessageData, setSupportMessageData] = useState(initSupportMessage)

  const { loading, data, success } = useFetch(requestURL)
  console.log("data", data)
  
  const projectSupportMessageReducer = useSelector(state => state.projectSupportMessageReducer)
 

  const handleOnchange = (e) => {
    const fieldName = e.target.name
    const fieldValue = e.target.value
    setSupportMessageData({
      ...supportMessageData,
      [fieldName]: fieldValue
    })

    console.log("supportMessageData : ", supportMessageData)
  }

  const submitData = (e) => {
    e.preventDefault();
    dispatch(createProjectSupportMessageRequest(supportMessageData))

  }

  const reloadPage = () => {
    window.location.reload(false)
  }



  return (
    <div className="row">
      <div className="col-lg-12">
        <div className="card">

          <div className="chat">
            <div className={`chat-header clearfix ${floatRight}`}>
              <div className="row">
                <div className="col-lg-12">
                  <a href="#" data-target="#view_info" data-toggle="modal">
                    <img alt="avatar" src={MAN_AVATAR} />
                  </a>
                  <div className="chat-about">
                    <h6 className="m-b-0">{supportObject.user_name}</h6>
                    <small>Support needed at {supportObject.start_date}</small>
                  </div>
                </div>
               
              </div>
            </div>
            <div className="chat-history">
              <ul className="m-b-0">

                {success && data.data.map( (item, i) =>{

                  if(userId()===item.sender_id)
                  {
                    return <Message is_mine={true} is_admin={false} time={item.time} message={item.message} />
                  }else{
                    return <Message is_mine={false} is_admin={false} time={item.time} message={item.message} />

                  }

                }) }

                {/* <Message is_mine={true} is_admin={false} time={"10:12 AM, Today"} message={"Hi Aiden, how are you? How is the project coming along?"} />
                <Message is_mine={false} is_admin={true} time={"10:12 AM, Today"} message={"Yes, it's good."} />
                <Message is_mine={false} is_admin={false} time={"10:12 AM, Today"} message={"Project has been already finished and I have results to show you."} /> */}

              </ul>
            </div>
            {supportObject.status==='open' && <div className="chat-message clearfix">
              <div className="input-group mb-0">
                <input
                  className="form-control"
                  placeholder="Enter text here..."
                  type="text"
                  name={"message"}
                  onChange={(e) => handleOnchange(e)}
                  value={supportMessageData.message}
                />
                <div className="input-group-prepend"  onClick={(e) => { submitData(e) }}>
                  <span className="input-group-text">
                    <i className="icon-paper-plane" ></i>
                  </span>
                </div>
              </div>
            </div>}
            {projectSupportMessageReducer.success && reloadPage()}
          </div>
        </div>
      </div>
    </div>
  )
}