import React, { useState } from 'react'
import { useHistory } from "react-router-dom"
import UerList from './UserList';

export default function TenantList(props) {


    const history = useHistory();


    const [anchorEl, setAnchorEl] = React.useState(null);


    const redirectCreateTenant = () => {
        history.push("/create-tenant")
    }



    return (
        <div className="col-lg-12">
            <div className="card">
                <div className="header">
                    Investor list
                </div>
                <UerList user_type="TENANT_USER" />
                
            </div>
        </div>

    )
}
