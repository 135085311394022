import React, { useState } from "react";
import Button from 'react-bootstrap/Button'
import Spinner from 'react-bootstrap/Spinner'
import PageHeader from "../../components/PageHeader"
import { useHistory, useLocation } from "react-router"
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { getDateOneFormatToAnother } from "../../utils/DateUtils"
import { useSelector, useDispatch } from "react-redux";
import { createOrUpdateProjectPackage } from "../../actions/projectPackageAction";
import Message from "../../utils/Message";


export default function CreateProjectPackage() {

  const history = useHistory();
  const location = useLocation()
  console.log("location.state >>>", location.state)
  const {packageDetails, project} = location.state
  const dispatch = useDispatch()
  const projectPackageReducer = useSelector(state => state.projectPackageReducer)

  console.log("create projct:::",project)
  console.log("packageDetails:::",packageDetails)

  let initPackage = {
    id: "",
    project_impl_id: project.id,
    year: project.year,
    package_name: "",
    contract_value: "",
    contract_date: "",
    contract_expire_date: "",
    financial_progress: "",
    actual_progress: ""

  }

  if(packageDetails)
  {
      initPackage = {
      id: packageDetails.id,
      project_impl_id: packageDetails.project_impl_id,
      year: packageDetails.year,
      package_name: packageDetails.package_name,
      contract_value: packageDetails.contract_value,
      contract_date: packageDetails.contract_date,
      contract_expire_date: packageDetails.contract_expire_date,
      financial_progress: packageDetails.financial_progress,
      actual_progress: packageDetails.actual_progress
  
    }
  }


  const [packageData, setPackageData] = useState(initPackage)
  const [package_start_date_picker, set_package_start_date_picker] = useState(new Date());
  const [package_end_date_picker, set_package_end_date_picker] = useState(new Date());

  const handleOnchange = (e) => {
    const fieldName = e.target.name
    const fieldValue = e.target.value
    setPackageData({
      ...packageData,
      [fieldName]: fieldValue
    })

    console.log("packageData : ", packageData)
  }



  const resetPageData = () => {
    //setProjectProfileData(initProjectProfile)
  }


  const submitData = (e) => {
    e.preventDefault();

   dispatch(createOrUpdateProjectPackage(packageData))

  }

  return (
    <div> {
      (<div className="body">
        <PageHeader
          HeaderText={`Save project package`}
          Breadcrumb={[
            { name: "project-package", navigate: "" },
            { name: "save", navigate: "" },
          ]}
        />
        <h6></h6>
        <div className="row clearfix">
          <div className="col-lg-6 col-md-12">
            
            <div className="form-group">
              <span>Package name</span>
              <input
                className="form-control"
                placeholder=""
                name="package_name"
                value={packageData.package_name}
                type="text"
                onChange={(e) => handleOnchange(e)}
              />
            </div>
            <div className="form-group">
              <span>Contract amount(Lakh taka)</span>
              <input
                className="form-control"
                placeholder=""
                name="contract_value"
                value={packageData.contract_value}
                type="text"
                onChange={(e) => handleOnchange(e)}
              />
            </div>


            <div className="form-group">
              <span>Contract date</span>
              <DatePicker dateFormat="yyyy-MM-dd" name="contract_date" className="form-control" selected={package_start_date_picker}
                onChange={(date) => {
                  set_package_start_date_picker(date)
                  setPackageData((state) => {
                    return {
                      ...state,
                      contract_date: getDateOneFormatToAnother(date, "yyyy-MM-DD")
                    }
                  })
                }
                }
              />
            </div>
            <div className="form-group">
              <span>Contract expire date</span>
              <DatePicker dateFormat="yyyy-MM-dd" name="contract_expire_date" className="form-control" selected={package_end_date_picker}
                onChange={(date) => {
                  set_package_end_date_picker(date)
                  setPackageData((state) => {
                    return {
                      ...state,
                      contract_expire_date: getDateOneFormatToAnother(date, "yyyy-MM-DD")
                    }
                  })
                }
                }
              />
            </div>


            <div className="form-group">
              <span>Financial progress(%)</span>
              <input
                className="form-control"
                placeholder=""
                name="financial_progress"
                value={packageData.financial_progress}
                type="text"
                onChange={(e) => handleOnchange(e)}
              />
            </div>

            <div className="form-group">
              <span>Actual progress(%)</span>
              <input
                className="form-control"
                placeholder=""
                name="actual_progress"
                value={packageData.actual_progress}
                type="text"
                onChange={(e) => handleOnchange(e)}
              />
            </div>



            <div className="form-group">

              {(projectPackageReducer.showMessage && !projectPackageReducer.isLoading) && <Message data={projectPackageReducer.updatedMessageDialog} />}

              {/* {initProjectSchedule && resetPageData()} */}
            </div>


            <div>

              <Button onClick={(e) => { submitData(e) }} variant="primary">
                {
                  projectPackageReducer.loading && <Spinner
                    as="span"
                    animation="grow"
                    size="sm"
                    role="status"
                    aria-hidden="true"
                  />
                }

                {projectPackageReducer.loading ? "Saving..." : "Save"}
              </Button>

              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;

              <Button onClick={(e) => {
                 history.push({pathname:"project-implementation-package-list", state: project})
                 }} variant="secondary">

                {"Back to package list"}
              </Button>
              <br></br>
              <br></br>
              <br></br>

            </div>

          </div>


        </div>
      </div>)}


    </div>
  )
}
