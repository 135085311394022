import { LOGIN_RESET_REQUEST, INVALID_INPUT, LOGIN_REQUEST, LOGIN_REQUEST_FAILURE, LOGIN_REQUEST_SUCCESS } from "../utils/constants";
import { initMessageObject } from "../utils/initMessage";



const initialState = {
  isLoading: false,
  error: null,
  login_response: {},
  login_success:false,
  showMessage: false,
  messageDialog: initMessageObject
};



const loginReducer = (state = initialState, action) => {
  switch (action.type) {


    case INVALID_INPUT: {


      const message = {...initMessageObject,
        show: true,
        title: "Login status",
        message: action.payload,
        messageType: "danger",
      }

      return {
        ...state,
        isLoading: false,
        error: action.payload,
        showMessage: true,
        messageDialog: message,
        login_response: {}
      };
    }

    case LOGIN_REQUEST: {
      return {
        ...state,
        isLoading: true,
        showMessage: false,
        messageDialog: initMessageObject,
        error: null
      };
    }

    case LOGIN_REQUEST_SUCCESS: {

      return {
        ...state,
        isLoading: false,
        login_response: action.payload,
        login_success: true
      };
    }

    case LOGIN_RESET_REQUEST: {

      return {
        ...state,
        isLoading: false,
        login_response: action.payload,
        login_success: false
      };
    }
    case LOGIN_REQUEST_FAILURE: {


      const message = {
        ...initMessageObject,
        show: true,
        title: "Login status",
        message: action.payload,
        messageType: "danger",
      }

      return {
        ...state,
        isLoading: false,
        error: action.payload,
        showMessage: true,
        messageDialog: message,
        login_response: {}
      };
    }


    default:
      return state;
  }
};

export default loginReducer;
