import axios from "axios";
import { BASE_HEADERS } from "../utils/baseValues";
import { createProjectSupportMessageURL } from "../utils/allurls";
import {
   INIT_CREATE_PROJECT_SUPPORT_MESSAGE_REQUEST,
CREATE_PROJECT_SUPPORT_MESSAGE_SUCCESS,
CREATE_PROJECT_SUPPORT_MESSAGE_FAILURE

} from "../utils/constants";

export const createProjectSupportMessageRequest = (data) => async (dispatch) => {

   dispatch({ type: INIT_CREATE_PROJECT_SUPPORT_MESSAGE_REQUEST, payload: data })

   try {

      const headers = BASE_HEADERS()
      const response = await axios.post(createProjectSupportMessageURL, data, { headers });
      console.log("create message response", response)

      if (response.status === 201) {

         dispatch({ type: CREATE_PROJECT_SUPPORT_MESSAGE_SUCCESS, payload: response.data })
      } else {
         dispatch({ type: CREATE_PROJECT_SUPPORT_MESSAGE_FAILURE, payload: response.error })
      }

   } catch (error) {

      dispatch({ type: CREATE_PROJECT_SUPPORT_MESSAGE_FAILURE, payload: error.response.data.error })
   }
}

