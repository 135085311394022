import React from "react";
import { connect } from "react-redux";
import PageHeader from "../../components/PageHeader";
import { userActions } from "../../actions";
import { Spinner } from "react-bootstrap";
import Message from "../../utils/Message";



class ChangePasswordSetttings extends React.Component {


  constructor(props) {
    super(props)

    this.state = {
      currentPassword: "",
      newPassword: "",
      confirmPassword: ""
    }

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);

  }

  handleChange(event) {

    // console.log(event.target)

    let fieldName = event.target.name
    let fieldValue = event.target.value;
    console.log(fieldName, fieldValue)

    this.setState({
      [fieldName]: fieldValue
    });

    console.log(this.state)



  }

  handleSubmit(event) {
    event.preventDefault();

    this.props.changePassword(this.state.currentPassword, this.state.newPassword, this.state.confirmPassword)
  
  }

  render() {


    const { isLoading, showMessage, messageDialog } = this.props



    return (
      <div>
        <PageHeader
          HeaderText="Change password"
          Breadcrumb={[

            { name: "updatepassword", navigate: "" },
          ]}
        />



        <div className="body">

          <div className="row clearfix">

            <div className="col-lg-6 col-md-12">
              <h6>Change Password</h6>
              <div className="form-group">
                <input
                  className="form-control"
                  placeholder="Current Password"
                  type="password"
                  onChange={this.handleChange}
                  name="currentPassword"
                />
              </div>
              <div className="form-group">
                <input
                  className="form-control"
                  placeholder="New Password"
                  type="password"
                  onChange={this.handleChange}
                  name="newPassword"
                />
              </div>
              <div className="form-group">
                <input
                  className="form-control"
                  placeholder="Confirm New Password"
                  type="password"
                  onChange={this.handleChange}
                  name="confirmPassword"
                />
              </div>
              <div className="form-group">
                {(showMessage && !isLoading) && <Message data={messageDialog} />}

              </div>
            </div>
          </div>
          <button className="btn btn-primary" type="button"
            onClick={this.handleSubmit}
          >
            {isLoading &&
              <Spinner
                as="span"
                animation="grow"
                size="sm"
                role="status"
                aria-hidden="true"
              />}
            {isLoading ? "Loading..." : "Update password"}
          </button>{" "}
          &nbsp;&nbsp;

        </div>

      </div>
    );
  }
}

const mapStateToProps = (state) => ({

  isLoading: state.changePassowrdReducer.isLoading,
  error: state.changePassowrdReducer.error,
  response: state.changePassowrdReducer.response,
  success: state.changePassowrdReducer.success,
  showMessage: state.changePassowrdReducer.showMessage,
  messageDialog: state.changePassowrdReducer.messageDialog,

});

const actionCreators = {
  changePassword: userActions.changePassword
}

export default connect(mapStateToProps, actionCreators)(ChangePasswordSetttings);
