import React, { useState } from "react";
import 'bootstrap/dist/css/bootstrap.min.css';
import { userActions } from "../../actions/ForgotPasswordAction";
import swal from "sweetalert";
import Message from "../../utils/Message";
import { Spinner } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux"
import { useHistory } from "react-router";
import "../login.css"
import Button from '@mui/material/Button';
import SearchIcon from '@mui/icons-material/Search';
import { styled, alpha } from '@mui/material/styles';
import InputBase from '@mui/material/InputBase';
import EKUSHEY_MONUMENT_BACKGROUND from "../../assets/images/bg/bottom-up-background.png"
import LOGIN_BACKGROUND from "../../assets/images/bg/background_login.png"
import BackgroundSlider from "react-background-slider";
import images from '../../assets/images/gallery'
import MUJIB_100_LOGO from "../../assets/images/icons/mujib-100-logo.png"
import BHTPA_LOGO_T from "../../assets/images/icons/bhtpa-logo-transparent.png"


const SearchIconWrapper = styled('div')(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: '100%',
  position: 'absolute',
  pointerEvents: 'none',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}));
const Search = styled('div')(({ theme }) => ({
  position: 'relative',
  borderRadius: theme.shape.borderRadius,
  backgroundColor: alpha(theme.palette.common.white, 0.15),
  '&:hover': {
    backgroundColor: alpha(theme.palette.common.white, 0.25),
  },
  marginRight: theme.spacing(2),
  marginLeft: 0,
  width: '100%',
  [theme.breakpoints.up('sm')]: {
    marginLeft: theme.spacing(3),
    width: 'auto',
  },
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: 'inherit',
  '& .MuiInputBase-input': {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create('width'),
    width: '100%',
    [theme.breakpoints.up('md')]: {
      width: '20ch',
    },
  },
}));

export default function ForgotPassword() {

  const [email, setemail] = useState("");
  const dispatch = useDispatch()
  const history = useHistory();
  const { isLoading, message, success, showMessage, messageDialog } = useSelector(state => state.forgetPasswordReducer);


  const submitForgotPassword = (e) => {
    e.preventDefault();

    dispatch(userActions.recoverPassword(email))
  }

  const showSuccesMessage = (message) => {

    swal(message).then(() => {
      history.push('/login')
    })

  }
  console.log(
    "forgot password render called...."
  );
  return (

    <div className="App">

      <div className="login-container">
        <div className="login-header">
          <img src={BHTPA_LOGO_T} width="200px" height="75px" alt="Background image" />
          <img className="margin10" src={MUJIB_100_LOGO} width="125px" height="100px" alt="Background image" />

        </div>

        <div></div>
        <div className="login-middle">

          <div className="login-background-image"> <img src={LOGIN_BACKGROUND} height="400px" width="auto" alt="Login background image" /></div>

          <div className="forgot-form-style">

            <div>

              {success && showSuccesMessage(message)}
              <div className="">
                <div className="header">
                  <p className="lead">Recover password</p>
                </div>
                <div className="body">
                  <p>Please enter your email address to receive instructions for resetting password.</p>
                  <form className="form-auth-small ng-untouched ng-pristine ng-valid">
                    <div className="form-group">
                      <input name="email" value={email} className="form-control" placeholder="Email" type="text" onChange={(e) => setemail(e.target.value)} />
                    </div>
                    <div className="form-group">
                      {(showMessage && !isLoading) && <Message data={messageDialog} />}
                    </div>


                    <Button style={{ color: "#ffffff", backgroundColor: "#609513", borderColor: "#ffffff", textTransform: 'none' }} className="btn btn-primary btn-lg btn-block" type="submit" onClick={submitForgotPassword}>
                      {isLoading &&
                        <Spinner
                          as="span"
                          animation="grow"
                          size="sm"
                          role="status"
                          aria-hidden="true"
                        />}
                      {isLoading ? "Loading..." : "Reset password"}

                    </Button>
                    <div className="bottom">
                      <span className="helper-text">Know your password? <a onClick={() => {
                        history.push("/login")
                      }} href="#">Login</a></span>
                    </div>
                  </form>
                </div>
              </div>
            </div>

          </div>


        </div>

        <div className="copyright-style">
          Copyright 2022. BHTPA - All Rights Reserved.
        </div>

      </div>

      <BackgroundSlider
        images={images}
        duration={5}
        transition={2}
      />

    </div>
  )
}