import swal from "sweetalert"
import { STATUS, ADMIN_USER, TENANT_USER, PD_USER, ACTIVE, LAST_SERVICE_TIME } from "./constants"
import { dateString, formatDateValue } from "./DateUtils"
import { userType } from "./userUtils"
import { getDateStringOnly, getTimeStringOnly } from "./DateUtils"
import { THINK_DEVICE_CONSTANT } from "./constants"
import { getRandomsparklineData } from "./dashboardDatastore"





export const transportType = [
  {
    id: 1,
    name: "default"
  },
  {
    id: 2,
    name: "MQTT"
  }
]



export const getUserEnabledStatus = (user) => {

  const { additionalInfo } = user

  if (additionalInfo.hasOwnProperty('userCredentialsEnabled')) {
    return additionalInfo.userCredentialsEnabled
  } else {
    return true
  }
}

export const dashboardURLBasedOnUserType = () => {

  if (userType() === ADMIN_USER) {
    return "dashboard"
  } else if (userType() === TENANT_USER) {
    return "tenant-user-dashboard"
  } else {
    return "user-dashboard"
  }
}

export const showCustomMessage = (message, icon = "warning") => {

  swal(message, {
    icon: icon,
  })

}

export const getUniqueId = () => {
  return 'widget-' + Date.now();
}

export const saveLocalStorageData = (itemName, data) => {
  localStorage.setItem(itemName, data);
}

export const getSavedLocalStorageData = (itemName) => {
  return localStorage.getItem(itemName);
}



export const getFilteredOption = (stateObject) => {

  const filterArray = []
  if (stateObject.valid_time) filterArray.push(1)
  if (stateObject.faulty_time) filterArray.push(2)
  if (stateObject.stop_time) filterArray.push(0)

  return joinArrayWithComma(filterArray)

}

export const joinArrayWithComma = (arrayObject) => {
  return arrayObject.join(",")
}



export const getFormattedAccountType = (type) => {
  if (type === ADMIN_USER) return "Admin"
  if (type === TENANT_USER) return "Tenant"
  if (type === PD_USER) return "PD Account"
  return type
}

export const getTenantDashboardDataList = (dashboardObj) => {

  const dashboardList = []

  if (dashboardObj.project_profile_tenant) {


    var inProjectNumber = dashboardObj.project_profile_tenant.total_number_of_project - (dashboardObj.project_profile_tenant.completed_project_number + dashboardObj.project_profile_tenant.pending_project_number)

    dashboardList.push({
      id: 1, name: "Number of investor profiles", value: dashboardObj.project_profile_tenant.total_number_of_project, valueType: "", bgColor: "light-blue",
      sparklineData: getRandomsparklineData()
    }, {
      id: 2, name: "Pending investor profile", value: dashboardObj.project_profile_tenant.pending_project_number, valueType: "", bgColor: "light-blue",
      sparklineData: getRandomsparklineData()
    },
      {
        id: 3, name: "In progress", value: inProjectNumber, valueType: "", bgColor: "green-bg",
        sparklineData: getRandomsparklineData()
      },
      {
        id: 4, name: "Completed", value: dashboardObj.project_profile_tenant.completed_project_number, valueType: "", bgColor: "dark-blue-bg",
        sparklineData: getRandomsparklineData()
      })
  }else if (dashboardObj.project_profile_admin) {


    var inProjectNumber = dashboardObj.project_profile_admin.total_number_of_project - (dashboardObj.project_profile_admin.completed_project_number + dashboardObj.project_profile_admin.pending_project_number)

    dashboardList.push({
      id: 1, name: "Number of investor profiles", value: dashboardObj.project_profile_admin.total_number_of_project, valueType: "", bgColor: "light-blue",
      sparklineData: getRandomsparklineData()
    }, {
      id: 2, name: "Pending investor profiles", value: dashboardObj.project_profile_admin.pending_project_number, valueType: "", bgColor: "light-blue",
      sparklineData: getRandomsparklineData()
    },
      {
        id: 3, name: "In progress", value: inProjectNumber, valueType: "", bgColor: "green-bg",
        sparklineData: getRandomsparklineData()
      },
      {
        id: 4, name: "Completed", value: dashboardObj.project_profile_admin.completed_project_number, valueType: "", bgColor: "dark-blue-bg",
        sparklineData: getRandomsparklineData()
      })
  }else if (dashboardObj.project_progress) {

    dashboardList.push({
      id: 1, name: "Total project number", value: dashboardObj.project_progress.project_number, valueType: "", bgColor: "light-blue",
      sparklineData: getRandomsparklineData()
    }, {
      id: 2, name: "Total project budget", value: dashboardObj.project_progress.total_expense, valueType: " Tk(Lac) ", bgColor: "light-blue",
      sparklineData: getRandomsparklineData()
    },
    {
      id: 3, name: "Total expenses till this year", value: dashboardObj.project_progress.expense_till_year, valueType: " Tk(Lac)", bgColor: "light-blue",
      sparklineData: getRandomsparklineData()
    },{
      id: 4, name: "Current year alloted", value: dashboardObj.project_progress.total_alloted, valueType: " Tk(Lac) ", bgColor: "light-blue",
      sparklineData: getRandomsparklineData()
    },{
      id: 5, name: "Current year release", value: dashboardObj.project_progress.total_release, valueType: " Tk(Lac) ", bgColor: "light-blue",
      sparklineData: getRandomsparklineData()
    },{
      id: 6, name: "Current year expenses", value: dashboardObj.project_progress.total_expended, valueType: " Tk(Lac) ", bgColor: "light-blue",
      sparklineData: getRandomsparklineData()
    },{
      id: 7, name: "Average total financial progress", value: dashboardObj.project_progress.financial_progress, valueType: "%", bgColor: "light-blue",
      sparklineData: getRandomsparklineData()
    },{
      id: 8, name: "Average total physical progress", value: dashboardObj.project_progress.physical_progress, valueType: "%", bgColor: "light-blue",
      sparklineData: getRandomsparklineData()
    },
      )
  }

  return dashboardList
}


export const getSupportStatsFormattedData = (dashboardObj) => {

  const dashboardList = []

  if (dashboardObj.support_tenant_dashboard) {



    dashboardList.push({
      id: 1, name: "Total number of supports", value: dashboardObj.support_tenant_dashboard.total_number_of_support, valueType: "", bgColor: "light-blue",
      sparklineData: getRandomsparklineData()
    }, {
      id: 2, name: "Open support numbers", value: dashboardObj.support_tenant_dashboard.open_support, valueType: "", bgColor: "light-blue",
      sparklineData: getRandomsparklineData()
    },
      {
        id: 3, name: "Resolved supports", value: dashboardObj.support_tenant_dashboard.resolved_support, valueType: "", bgColor: "green-bg",
        sparklineData: getRandomsparklineData()
      },
      {
        id: 4, name: "Closed support numbers", value: dashboardObj.support_tenant_dashboard.closed_support, valueType: "", bgColor: "dark-blue-bg",
        sparklineData: getRandomsparklineData()
      })
  }else if (dashboardObj.support_admin_dashboard) {


    dashboardList.push({
      id: 1, name: "Total number of supports", value: dashboardObj.support_admin_dashboard.total_number_of_support, valueType: "", bgColor: "light-blue",
      sparklineData: getRandomsparklineData()
    }, {
      id: 2, name: "Open support numbers", value: dashboardObj.support_admin_dashboard.open_support, valueType: "", bgColor: "light-blue",
      sparklineData: getRandomsparklineData()
    },
      {
        id: 3, name: "Resolved supports", value: dashboardObj.support_admin_dashboard.resolved_support, valueType: "", bgColor: "green-bg",
        sparklineData: getRandomsparklineData()
      },
      {
        id: 4, name: "Closed support numbers", value: dashboardObj.support_admin_dashboard.closed_support, valueType: "", bgColor: "dark-blue-bg",
        sparklineData: getRandomsparklineData()
      })
  }

  return dashboardList
}


export const getExtensionStatsFormattedData = (dashboardObj) => {

  const dashboardList = []

  if (dashboardObj.extention_tenant_dashboard) {



    dashboardList.push({
      id: 1, name: "Total extension numbers", value: dashboardObj.extention_tenant_dashboard.total_number_of_extention, valueType: "", bgColor: "light-blue",
      sparklineData: getRandomsparklineData()
    }, {
      id: 2, name: "Pending extensions", value: dashboardObj.extention_tenant_dashboard.pending_extention, valueType: "", bgColor: "light-blue",
      sparklineData: getRandomsparklineData()
    },
      {
        id: 3, name: "Approved extensions", value: dashboardObj.extention_tenant_dashboard.approved_extention, valueType: "", bgColor: "green-bg",
        sparklineData: getRandomsparklineData()
      },
      )
  }else if (dashboardObj.extention_admin_dashboard) {


    dashboardList.push({
      id: 1, name: "Total extension numbers", value: dashboardObj.extention_admin_dashboard.total_number_of_extention, valueType: "", bgColor: "light-blue",
      sparklineData: getRandomsparklineData()
    }, {
      id: 2, name: "Pending extensions", value: dashboardObj.extention_admin_dashboard.pending_extention, valueType: "", bgColor: "light-blue",
      sparklineData: getRandomsparklineData()
    },
      {
        id: 3, name: "Approved extensions", value: dashboardObj.extention_admin_dashboard.approved_extention, valueType: "", bgColor: "green-bg",
        sparklineData: getRandomsparklineData()
      },
      )
  }

  return dashboardList
}


export const getScheduleStatsFormattedData = (dashboardObj) => {

  const dashboardList = []

  if (dashboardObj.schedule_tenant_dashboard) {

    const inProgressNumber = dashboardObj.schedule_tenant_dashboard.approved_schedule+dashboardObj.schedule_tenant_dashboard.completed_schedule



    dashboardList.push({
      id: 1, name: "Total number of schedule", value: dashboardObj.schedule_tenant_dashboard.total_number_of_schedule, valueType: "", bgColor: "light-blue",
      sparklineData: getRandomsparklineData()
    }, {
      id: 1, name: "Schedule in progress", value: inProgressNumber, valueType: "", bgColor: "light-blue",
      sparklineData: getRandomsparklineData()
    },
      {
        id: 2, name: "Completed schedule", value: dashboardObj.schedule_tenant_dashboard.completed_schedule_final, valueType: "", bgColor: "green-bg",
        sparklineData: getRandomsparklineData()
      },
      {
        id: 3, name: "Pending schedule", value: dashboardObj.schedule_tenant_dashboard.pending_schedule, valueType: "", bgColor: "dark-blue-bg",
        sparklineData: getRandomsparklineData()
      })
  }else if (dashboardObj.schedule_admin_dashboard) {

    const inProgressNumber = dashboardObj.schedule_admin_dashboard.approved_schedule+dashboardObj.schedule_admin_dashboard.completed_schedule


    dashboardList.push({
      id: 1, name: "Total number of schedule", value: dashboardObj.schedule_admin_dashboard.total_number_of_schedule, valueType: "", bgColor: "light-blue",
      sparklineData: getRandomsparklineData()
    }, {
      id: 1, name: "Schedule in progress", value: inProgressNumber, valueType: "", bgColor: "light-blue",
      sparklineData: getRandomsparklineData()
    },
      {
        id: 2, name: "Completed schedule", value: dashboardObj.schedule_admin_dashboard.completed_schedule_final, valueType: "", bgColor: "green-bg",
        sparklineData: getRandomsparklineData()
      },
      {
        id: 3, name: "Pending schedule", value: dashboardObj.schedule_admin_dashboard.pending_schedule, valueType: "", bgColor: "dark-blue-bg",
        sparklineData: getRandomsparklineData()
      })
  }

  return dashboardList
}



export const getRentStatsFormattedData = (dashboardObj) => {

  const dashboardList = []

  if (dashboardObj.rent_tenant_dashboard) {

    



    dashboardList.push({
      id: 1, name: "Total number of Rent", value: dashboardObj.rent_tenant_dashboard.total_number_of_rent, valueType: "", bgColor: "light-blue",
      sparklineData: getRandomsparklineData()
    }, {
      id: 1, name: "Paid rent", value: dashboardObj.rent_tenant_dashboard.paid_rent, valueType: "", bgColor: "light-blue",
      sparklineData: getRandomsparklineData()
    },
      {
        id: 2, name: "Pending rent", value: dashboardObj.rent_tenant_dashboard.pending_rent, valueType: "", bgColor: "green-bg",
        sparklineData: getRandomsparklineData()
      })
  }else if (dashboardObj.rent_admin_dashboard) {



    dashboardList.push({
      id: 1, name: "Total number of schedule", value: dashboardObj.rent_admin_dashboard.total_number_of_rent, valueType: "", bgColor: "light-blue",
      sparklineData: getRandomsparklineData()
    }, {
      id: 1, name: "Rent paid", value: dashboardObj.rent_admin_dashboard.paid_rent, valueType: "", bgColor: "light-blue",
      sparklineData: getRandomsparklineData()
    },
      {
        id: 2, name: "Pending rent", value: dashboardObj.rent_admin_dashboard.pending_rent, valueType: "", bgColor: "green-bg",
        sparklineData: getRandomsparklineData()
      })
  }

  return dashboardList
}

export const getDashboardPiechartData = (dashboardObj) => {

  var data = {
    total: 0, pending: 0, in_progress: 0, completed: 0
  }

  if (dashboardObj.project_profile_admin) {


    var inProgressNumber = dashboardObj.project_profile_admin.total_number_of_project - (dashboardObj.project_profile_admin.completed_project_number + dashboardObj.project_profile_admin.pending_project_number)


    data = {
      ...data, total: dashboardObj.project_profile_admin.total_number_of_project,
      pending: dashboardObj.project_profile_admin.pending_project_number,
      completed: dashboardObj.project_profile_admin.completed_project_number,
      in_progress: inProgressNumber
    }
  }

  return data
}

export const getProjectUpdateJsonBody = (projectId, status) => {

  return {
    id:projectId,
    status: status
  }

}

export const getProjectScheduleUpdateJsonBody = (projectId, status) => {

  return {
    id:projectId,
    status: status
  }

}

