import React, { useEffect, useState } from "react";
import 'bootstrap/dist/css/bootstrap.min.css';
import { userActions } from "../actions";
import { userType } from '../utils/userUtils'
import { Spinner } from "react-bootstrap";
import Message from "../utils/Message";
import { ADMIN_USER, TENANT_USER, PD_USER } from "../utils/constants";
import { useHistory } from "react-router-dom"
import { useDispatch, useSelector } from "react-redux"
import "./login.css"
import MUJIB_100_LOGO from "../assets/images/icons/mujib-100-logo.png"
import BHTPA_LOGO_T from "../assets/images/icons/bhtpa-logo-transparent.png"
import Button from '@mui/material/Button';
import images from '../assets/images/gallery'

import BackgroundSlider from "react-background-slider"


export default function Login() {

  const history = useHistory()
  const dispatch = useDispatch()
  const [rememberMe, setRememberMe] = useState(false)
  const [screenData, setScreenData] = useState({

    username: '',
    password: '',
    submitted: false,
    rememberMe: localStorage.getItem('rememberMe')
  })

  const loginReducerData = useSelector(state => state.loginReducer)

  const { isLoading, error, email, password, login_success, showMessage, messageDialog } = loginReducerData;

  useEffect(() => {
    document.body.classList.remove("theme-cyan");
    document.body.classList.remove("theme-purple");
    document.body.classList.remove("theme-blue");
    document.body.classList.remove("theme-green");
    document.body.classList.remove("theme-orange");
    document.body.classList.remove("theme-blush");

  }, []);



  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      requestLogin()
    }

  }

  const handleOnChange = (e) => {

    const name = e.target.name;
    console.log("name: value", name, e.target.value)
    setScreenData({ ...screenData, [name]: e.target.value });
    console.log("screenData", screenData)
  }

  const submitLogin = (e) => {
    e.preventDefault();
    console.log("submit screenData", screenData)
    requestLogin()

  }

  const requestLogin = () => {
    console.log("login reqeust screenData", screenData)
    console.log("email and password", screenData.username, screenData.password)
    setScreenData({ ...screenData, submitted: true });
    dispatch(userActions.login(screenData.username, screenData.password))
  }

  const redirectUrl = () => {

    if (userType() === ADMIN_USER) {
      history.push('/dashboard')
    } else if (userType() === TENANT_USER) {
      history.push('/tenant-user-dashboard')
    } else if (userType() === PD_USER) {
      history.push('/pd-user-dashboard')
    }else {
      history.push('/tenant-user-dashboard')
    }
  }

  const loginError = (error) => {

  }

  return (


    <div className="App">


      <div className="login-container">
        {
          login_success === true ? redirectUrl() : loginError(error)
        }
        <div className="login-header">
          <img src={BHTPA_LOGO_T} width="200px" height="75px" alt="Background image" />
          <img className="margin10" src={MUJIB_100_LOGO} width="125px" height="100px" alt="Background image" />

        </div>

        <div></div>
        <div className="login-middle">

          <div className="login-background-image"></div>

          <div className="login-form-style">
            <div className="form-style-middle">
              <div className="header text-center">
                <div className="font-size-32px font-style-bold">Welcome</div>
                <div className="font-size-24px font-style-bold">Digital dashboard</div>
              </div>
              <div className="body">
                <div className="form-auth-small" action="index.html">
                  <div className="form-group">
                    <label className="control-label sr-only">Email</label>
                    <input
                      className="form-control"
                      id="signin-email"
                      placeholder="Email"
                      type="email"
                      value={email}
                      name="username"
                      // value="user@buddybees.co"
                      onChange={handleOnChange}
                      onKeyDown={handleKeyDown}
                    />
                  </div>
                  <div className="form-group">
                    <label className="control-label sr-only">
                      Password
                    </label>
                    <input
                      className="form-control"
                      id="signin-password"
                      placeholder="Password"
                      name="password"
                      type="password"
                      value={password}
                      onChange={handleOnChange}
                      onKeyDown={handleKeyDown}

                    />
                  </div>

                  <div className="form-group clearfix">
                    <label className="fancy-checkbox element-left">
                      <input type="checkbox" checked={rememberMe} onChange={() => {
                        setRememberMe((value) => {
                          return !value
                        })
                      }} />
                      <span>Remember me</span>
                    </label>
                  </div>
                  <div className="form-group clearfix">

                    {(showMessage && !isLoading) && <Message data={messageDialog} />}


                  </div>
                  <Button onClick={submitLogin} variant="contained" type
                    style={{ backgroundColor: "#609513", textTransform: 'none' }}
                  >
                    {isLoading &&
                      <Spinner
                        as="span"
                        animation="grow"
                        size="sm"
                        role="status"
                        aria-hidden="true"
                      />}
                    {isLoading ? "Loading..." : "Sign In"}
                  </Button>



                </div>
              </div>
              <div className="bottom">
                <div><span className="helper-text m-b-10">
                  <i className="fa fa-lock"></i>{" "}
                  <a href={`${process.env.PUBLIC_URL}/forgotpassword`}
                  >
                    Forgot password?
                  </a>
                </span></div>
                <div><span>
                  Don't have an account? Please contact BHTPA
                </span></div>


              </div>
            </div>
          </div>

        </div>

        <div className="login-bottom-last">
          Copyright 2022. BHTPA - All Rights Reserved.
        </div>

      </div>

      <BackgroundSlider
        images={images}
        duration={5}
        transition={2}
      />

    </div>


  );
}