import { INIT_CREATE_PROJECT_SCHEDULE,
  CREATE_PROJECT_SCHEDULE_SUCCESS,
  CREATE_PROJECT_SCHEDULE_FAILURE,
  INIT_PROJECT_SCHEDULE_UPDATE,
  PROJECT_SCHEDULEUPDATE_SUCCESS,
  PROJECT_SCHEDULE_UPDATE_FAILURE,
  INIT_PROJECT_SCHEDULE_DELETE,
PROJECT_SCHEDULE_DELETE_SUCCESS,
PROJECT_SCHEDULE_DELETE_FAILURE
 } from "../utils/constants"

const initialState = {
  isLoading: false,
  showMessage: false,
  updatedMessageDialog: {},
  error: null,
  response: {},
  success:false,
  updateSuccess:false,
  deleteSuccess:false,
};

const projectScheduleReducer = (state = initialState, action) => {

  switch (action.type) {

    case INIT_CREATE_PROJECT_SCHEDULE: {

      return {
        ...state,
        user: action.payload,
        isLoading: true
      };
    }
    case CREATE_PROJECT_SCHEDULE_SUCCESS: {

      const message = {
        show: true,
        title: "Project schedule status",
        message:"Project schedule created successfully.",
        messageType: "success",
      }

      return {
        ...state,
        isLoading: false,
        response: action.payload,
        updatedMessageDialog: message,
        showMessage: true,
        success: true,


      }
    } 
    case CREATE_PROJECT_SCHEDULE_FAILURE: {

      const message = {
        show: true,
        title: "Project schedule status",
        message: action.payload,
        messageType: "danger",
      }

      return {
        ...state,
        isLoading: false,
        error: action.payload,
        updatedMessageDialog: message,
        showMessage: true,

      }
    }

    case INIT_PROJECT_SCHEDULE_UPDATE: {

      return {
        ...state,
        user: action.payload,
        isLoading: true
      };
    }

    case PROJECT_SCHEDULEUPDATE_SUCCESS: {

      const message = {
        show: true,
        title: "Project schedule update status",
        message:"Project schedule updated successfully.",
        messageType: "success",
      }

      return {
        ...state,
        isLoading: false,
        response: action.payload,
        updatedMessageDialog: message,
        showMessage: true,
        updateSuccess: true,

      }
    } 
    case PROJECT_SCHEDULE_UPDATE_FAILURE: {

      const message = {
        show: true,
        title: "Project schedule update status",
        message: action.payload,
        messageType: "danger",
      }

      return {
        ...state,
        isLoading: false,
        error: action.payload,
        updatedMessageDialog: message,
        showMessage: true,

      }
    }

    case INIT_PROJECT_SCHEDULE_DELETE: {

      return {
        ...state,
        user: action.payload,
        isLoading: true
      };
    }

    case PROJECT_SCHEDULE_DELETE_SUCCESS: {

      const message = {
        show: true,
        title: "Project schedule delete status",
        message:"Project schedule deleted successfully.",
        messageType: "success",
      }

      return {
        ...state,
        isLoading: false,
        response: action.payload,
        updatedMessageDialog: message,
        showMessage: true,
        deleteSuccess: true,

      }
    } 
    case PROJECT_SCHEDULE_DELETE_FAILURE: {

      const message = {
        show: true,
        title: "Project schedule delete status",
        message: action.payload,
        messageType: "danger",
      }

      return {
        ...state,
        isLoading: false,
        error: action.payload,
        updatedMessageDialog: message,
        showMessage: true,

      }
    }

    default:
      return state;
  }
};


export default projectScheduleReducer;
