import React, { useState } from 'react'
import Button from '@mui/material/Button';
import { useHistory } from "react-router-dom"
import PageHeader from '../../components/PageHeader';

import UerList from './UserList';


export default function AdminUerList() {

    const history = useHistory();


    const createNewUser = () => {
        history.push("/admin-create-user")
    }

    return (

        <div className="dashboard-container">
            <PageHeader
                HeaderText="User list"
                Breadcrumb={[{ name: "Management" }, { name: "User list" }]}
                
            />
            <div className="col-lg-12">
                <div className="card">
                    <div className="header">
                        <Button onClick={createNewUser} variant="contained">Create new user</Button>
                    </div>
                    <UerList user_type="ALL" />
                </div>
            </div>
        </div>



    )
}
