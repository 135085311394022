import React from "react";
import PageHeader from "../../components/PageHeader";
import { ADMIN_USER, PD_USER } from "../../utils/constants";
import Button from 'react-bootstrap/Button'
import { useHistory, useLocation } from "react-router"
import ProjectPackageRow from "./ProjectPackageRow";
import { userType } from "../../utils/userUtils"

export default function ProjectPackageDashboard(props) {

  const history = useHistory()
  const location = useLocation()

  const projectObject = location.state
  console.log("project impl list:", projectObject)

  return (
    <div className="dashboard-container">
      <PageHeader
        HeaderText="Project packages"
        Breadcrumb={[{ name: "project-packages" }]}
      />
      <div className={`displayVertical`}>
        <div className={"display-two-column"}>
          <div><Button variant="secondary" onClick={() => {

            if (userType() === ADMIN_USER) {
              history.push({ pathname: "project-implemention-dashboard" })
              //history.push({pathname: "project-implemention-dashboard"})
              //project-implementation-package-list
            } else if (userType() === PD_USER) {
              history.push({ pathname: "pd-user-dashboard", state: { } })
              
            }

          }} >{"Back"}</Button></div>

        </div>

      </div>
      <div>
        <ProjectPackageRow user_type={ADMIN_USER} project={projectObject} />

      </div>
    </div>
  )
}


