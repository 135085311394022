import React from "react";
import { IMAGE_BASE_URL } from "../utils/baseValues"


export default function ContactCard(props) {

  const {data} = props

  return (
    <div className="col-lg-3 col-md-6 col-sm-12">
        <div className="card">
          <div className="body text-center">
            <div className="chart easy-pie-chart-1" data-percent="75">
              {" "}
              <span>
                <img
                  src={`${IMAGE_BASE_URL}${data.picture}`}
                  alt={`${data.name} contact user`}
                  className="rounded-circle"
                />
              </span>{" "}
              <canvas height="100" width="100">
              </canvas>
            </div>
            <h6>{data.name}</h6>
            <ul className="social-links list-unstyled">
              <li>
                <a title="facebook">
                  <i className="zmdi zmdi-facebook"></i>
                </a>
              </li>
              <li>
                <a title="twitter">
                  <i className="zmdi zmdi-twitter"></i>
                </a>
              </li>
              <li>
                <a title="instagram">
                  <i className="zmdi zmdi-instagram"></i>
                </a>
              </li>
            </ul>
            <small>
              {data.title}
              <br />
              {data.contact_phone && `Phone: ${data.contact_phone}`}{" "}<br></br>
              {data.contact_email && `Email: ${data.contact_email}`}{" "}<br></br>
            </small>
          </div>
        </div>
      </div>
  )
}