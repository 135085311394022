import { BASE_HEADERS } from "../utils/baseValues";

import axios from 'axios'
import { getLoginURL, getChangePasswordURL, getMyUserDetailsURL, getUserLogoutURL } from "../utils/allurls";
import { LOGIN_RESET_REQUEST, CHANGE_PASSWORD_REQUEST, CHANGE_PASSWORD_REQUEST_FAILURE, CHANGE_PASSWORD_REQUEST_SUCCESS, INPUT_VALIDATION_ERROR, INVALID_INPUT, LOGIN_REQUEST, LOGIN_REQUEST_FAILURE, LOGIN_REQUEST_SUCCESS, MY_PROFILE_DETAILS_JSON, USER_LOGOUT_REQUEST, USER_LOGOUT_REQUEST_FAILURE, USER_LOGOUT_REQUEST_SUCCESS } from "../utils/constants";
import { initMessageObject } from "../utils/initMessage";
import { setRefreshToken, setToken } from "../utils/TokenManage";

const login = (email, password) => async (dispatch, getState) => {

   if (!email) {

      dispatch({ type: INVALID_INPUT, payload: "Email field is blank!" })
      return
   } else if (!password) {
      dispatch({ type: INVALID_INPUT, payload: "Password field is blank!" })
      return
   }

   dispatch({ type: LOGIN_REQUEST })

   try {

      const inputBody = {
         email: email,
         password: password
      }

      const headers = BASE_HEADERS()
      const response = await axios.post(getLoginURL, inputBody, { headers });

      if (response.status === 200) {

         setToken(response.data.token)
         setRefreshToken(response.data.refreshToken)
         localStorage.setItem('isLoggedIn', true)
         const userJSON = JSON.stringify(response.data.user)
         localStorage.setItem(MY_PROFILE_DETAILS_JSON, userJSON)

         dispatch({ type: LOGIN_REQUEST_SUCCESS, payload: response.data.user })

      }  else {
         dispatch({type: LOGIN_REQUEST_FAILURE, payload: response.data.message })
      }

   } catch (error) {
      console.log("Error>>>>>>>>>>>",error)

      dispatch({ type: LOGIN_REQUEST_FAILURE, payload: error.response.data.error })

   }

}

export const loginDataReset = () => async (dispatch, getState) => {


   dispatch({ type: LOGIN_RESET_REQUEST })

}

const changePassword = (currentPassword, newPassword, confirmPassword) => async (dispatch, getState) => {

   var errorMessage = {

   }

   if (!currentPassword) {

      errorMessage = {
         ...initMessageObject,
         show: true,
         title: "Input validation status",
         message: "Current password field is blank!",
         messageType: "danger"
      }
   } else if (!newPassword) {

      errorMessage = {
         ...initMessageObject,
         show: true,
         title: "Input validation status",
         message: "New password field is blank",
         messageType: "danger"
      }

   } else if (!confirmPassword) {
      errorMessage = {
         ...initMessageObject,
         show: true,
         title: "Input validation status",
         message: "Confirm password field is blank!",
         messageType: "danger"
      }

   } else if (newPassword !== confirmPassword) {
      errorMessage = {
         ...initMessageObject,
         show: true,
         title: "Input validation status",
         message: "New password and confirm password not matched!",
         messageType: "danger"
      }

   }

   if (errorMessage.show) {
      dispatch({ type: INPUT_VALIDATION_ERROR, payload: errorMessage })
      return
   }

   dispatch({ type: CHANGE_PASSWORD_REQUEST })

   try {

      const inputBody = {
         current_password: currentPassword,
         new_password: newPassword
      }

      const headers = BASE_HEADERS()

      const response = await axios.post(getChangePasswordURL, inputBody, { headers });

      if (response.status === 200) {

         if(response.data.status === true)
         {
            setToken(response.data.token)
            setRefreshToken(response.data.refreshToken)
         }

         dispatch({ type: CHANGE_PASSWORD_REQUEST_SUCCESS, payload: response.data })

      } else {
         dispatch({ type: CHANGE_PASSWORD_REQUEST_FAILURE, payload: response.error })
      }



   } catch (error) {

      dispatch({ type: CHANGE_PASSWORD_REQUEST_FAILURE, payload: error.response.data.error })


   }

}

export const userLogout = () => async (dispatch, getState) => {

   dispatch({ type: USER_LOGOUT_REQUEST })


   try {

      const inputData = {

      }

      const headers = BASE_HEADERS()
      const response = await axios.post(getUserLogoutURL, inputData, { headers });

      if (response.status === 200) {
        
         dispatch({ type: USER_LOGOUT_REQUEST_SUCCESS, payload: response.data })

      } else {
         dispatch({ type: USER_LOGOUT_REQUEST_FAILURE, payload: response.error })
      }



   } catch (error) {

     
      //console.log("error", error)
      dispatch({ type: USER_LOGOUT_REQUEST_FAILURE, payload: "Failed" })

   }

}

export const userActions = {
   login,
   changePassword
}
