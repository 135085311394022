import React, { useState, useEffect, useCallbacks, useCallback } from 'react';
import axios from 'axios';
import { BASE_HEADERS } from '../utils/baseValues';

export const useFetch = (url) => {
  const [loading, setLoading] = useState(true);
  const [success, setSuccess] = useState(false);
  const [data, setData] = useState([]);

  const getData = async () => {
    const headers = BASE_HEADERS();
    const response = await axios.get(url, { headers });
    if (response.status === 200) {
      setData(response.data);
      setSuccess(true);
    }

    setLoading(false);
  };

  useEffect(() => {
    getData();
  }, []);

  return { loading, data, success };
};

export const useFetchDataFrom = (url) => {
  const [initUrl, setInitUrl] = useState(url);
  const [loading, setLoading] = useState(true);
  const [success, setSuccess] = useState(false);
  const [data, setData] = useState([]);

  const getData = useCallback(async (urlAsParm) => {
    const headers = BASE_HEADERS();
    const response = await axios.get(urlAsParm, { headers });
    if (response.status === 200) {
      setData(response.data);
      setSuccess(true);
    }
    setLoading(false);
  }, []);

  useEffect(() => {
    getData(initUrl);
  }, [initUrl, getData]);

  const disPatcher = (url) => {
    setInitUrl(url);
  };
  return { loading, data, success, disPatcher };
};
