import axios from "axios";
import { BASE_HEADERS } from "../utils/baseValues";
import { myProfileUpdateURL } from "../utils/allurls";
import {
   REQUEST_PROFILE_UPDATE,
   REQUEST_PROFILE_UPDATE_SUCCESS,
   REQUEST_PROFILE_UPDATE_FAILED,
   MY_PROFILE_DETAILS_JSON
} from "../utils/constants";

export const userProfileUpdate = (userData) => async (dispatch) => {

   dispatch({ type: REQUEST_PROFILE_UPDATE, payload: userData })

   try {

      const headers = BASE_HEADERS()
      const response = await axios.post(myProfileUpdateURL, userData, { headers });

      if (response.status === 200) {

         const userJSON = JSON.stringify(response.data.user)

         localStorage.setItem(MY_PROFILE_DETAILS_JSON, userJSON)

         dispatch({ type: REQUEST_PROFILE_UPDATE_SUCCESS, payload: response.data })
      } else {
         dispatch({ type: REQUEST_PROFILE_UPDATE_FAILED, payload: response.error })
      }

   } catch (error) {

      dispatch({ type: REQUEST_PROFILE_UPDATE_FAILED, payload: error.response.data.error })
   }
}