import React from 'react'
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import { CardActionArea } from '@mui/material';

export default function SupportSummary(props) {

    const { supportObject } = props

  return (
    <Card sx={{ maxWidth: "100%" }}>
    <CardActionArea>
      <CardContent>
        <Typography gutterBottom variant="h5" component="div">
        {supportObject.subject}
        </Typography>
        <Typography variant="body2" color="text.secondary">
        {supportObject.details}
        </Typography>
      </CardContent>
    </CardActionArea>
  </Card>
    
  )
}
