import axios from "axios";
import { BASE_HEADERS } from "../utils/baseValues";
import {  deleteRentURL,
   updateRentURL } from "../utils/allurls";
import {
   INIT_UPDATE_RENT_REQUEST,
   UPDATE_RENT_SUCCESS,
   UPDATE_RENT_FAILURE,
   INIT_DELETE_RENT_REQUEST,
   DELETE_RENT_SUCCESS,
   DELETE_RENT_FAILURE,
} from "../utils/constants";


export const updateRentAttribute = (data) => async (dispatch) => {

   dispatch({ type: INIT_UPDATE_RENT_REQUEST, payload: data })

   try {

      const headers =  BASE_HEADERS()
      const response = await axios.post(updateRentURL, data, { headers });
      console.log("update rent response", response)

      if (response.status === 200) {

         dispatch({ type: UPDATE_RENT_SUCCESS, payload: response.data })
      } else {
         dispatch({ type: UPDATE_RENT_FAILURE, payload: response.error })
      }

   } catch (error) {

      dispatch({ type: UPDATE_RENT_FAILURE, payload: error.response.data.error })
   }
}


export const deleteRent = (rentid) => async (dispatch) => {


   dispatch({ type: INIT_DELETE_RENT_REQUEST, payload: rentid })

   try {

      const headers = BASE_HEADERS()
      const response = await axios.delete(deleteRentURL(rentid), { headers });
      console.log("delete rent response", response)

      if (response.status === 202) {

         dispatch({ type: DELETE_RENT_SUCCESS, payload: response.data })
      } else {
         dispatch({ type: DELETE_RENT_FAILURE, payload: response.error })
      }

   } catch (error) {

      dispatch({ type: DELETE_RENT_FAILURE, payload: error.response.data.error })
   }
}