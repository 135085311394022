import Moment from 'moment'

export const getYearFromDate = (value) =>{

   const format = "YYYY" 
   const date = new Date(value)
   const newFormatValue = Moment(date).format(format)

   return newFormatValue ? newFormatValue : ""
}

export const getMonthYearFromDate = (value) =>{

    const format = "MMM, YYYY" 
    const date = new Date(value)
    const newFormatValue = Moment(date).format(format)
 
    return newFormatValue ? newFormatValue : ""
 }

 export const getTotal = (...value) =>{

    let total = 0

    for(let i=0; i<value.length; i++) {
        total+= value[i] ? value[i]: 0
    }

    return total
 }

 export const getPercentage = (value1, value2) =>{

    let percentage = 0
    if(!value2) return percentage

    percentage = ((value1?value1:0)/value2)*100;

    return parseFloat(percentage.toFixed(2));
 }
