import axios from "axios";
import { BASE_HEADERS } from "../utils/baseValues";
import { createProjectScheduleURL, updateProjectScheduleStatusURL,deleteProjectScheduleURL } from "../utils/allurls";
import {
   INIT_CREATE_PROJECT_SCHEDULE,
CREATE_PROJECT_SCHEDULE_SUCCESS,
CREATE_PROJECT_SCHEDULE_FAILURE,
INIT_PROJECT_SCHEDULE_UPDATE,
PROJECT_SCHEDULEUPDATE_SUCCESS,
PROJECT_SCHEDULE_UPDATE_FAILURE,
INIT_PROJECT_SCHEDULE_DELETE,
PROJECT_SCHEDULE_DELETE_SUCCESS,
PROJECT_SCHEDULE_DELETE_FAILURE
} from "../utils/constants";

export const createProjectSchedule = (data) => async (dispatch) => {

   dispatch({ type: INIT_CREATE_PROJECT_SCHEDULE, payload: data })

   try {

      const headers = BASE_HEADERS()
      const response = await axios.post(createProjectScheduleURL, data, { headers });
      console.log("create response", response)

      if (response.status === 201) {

         dispatch({ type: CREATE_PROJECT_SCHEDULE_SUCCESS, payload: response.data })
      } else {
         dispatch({ type: CREATE_PROJECT_SCHEDULE_FAILURE, payload: response.error })
      }

   } catch (error) {

      dispatch({ type: CREATE_PROJECT_SCHEDULE_FAILURE, payload: error.response.data.error })
   }
}


export const updateProjectScheduleStatus = (data) => async (dispatch) => {

   dispatch({ type: INIT_PROJECT_SCHEDULE_UPDATE, payload: data })

   try {

      const headers = BASE_HEADERS()
      const response = await axios.post(updateProjectScheduleStatusURL, data, { headers });
      console.log("update response", response)

      if (response.status === 200) {

         dispatch({ type: PROJECT_SCHEDULEUPDATE_SUCCESS, payload: response.data })
      } else {
         dispatch({ type: PROJECT_SCHEDULE_UPDATE_FAILURE, payload: response.error })
      }

   } catch (error) {

      dispatch({ type: PROJECT_SCHEDULE_UPDATE_FAILURE, payload: error.response.data.error })
   }
}


export const deleteProjectSchedule = (scheduleId) => async (dispatch) => {


   dispatch({ type: INIT_PROJECT_SCHEDULE_DELETE, payload: scheduleId })

   try {

      const headers = BASE_HEADERS()
      const response = await axios.delete(deleteProjectScheduleURL(scheduleId), { headers });
      console.log("delete schedule response", response)

      if (response.status === 202) {

         dispatch({ type: PROJECT_SCHEDULE_DELETE_SUCCESS, payload: response.data })
      } else {
         dispatch({ type: PROJECT_SCHEDULE_DELETE_FAILURE, payload: response.error })
      }

   } catch (error) {

      dispatch({ type: PROJECT_SCHEDULE_DELETE_FAILURE, payload: error.response.data.error })
   }
}