export const REQUEST_PROFILE_UPDATE = "REQUEST_PROFILE_UPDATE"
export const REQUEST_PROFILE_UPDATE_SUCCESS = "REQUEST_PROFILE_UPDATE_SUCCESS"
export const REQUEST_PROFILE_UPDATE_FAILED = "REQUEST_PROFILE_UPDATE_FAILED"
export const LOGIN_RESET_REQUEST = "LOGIN_RESET_REQUEST"

export const REQUEST_CREATE_ACCOUNT = "REQUEST_CREATE_ACCOUNT"
export const REQUEST_CREATE_ACCOUNT_SUCCESS = "REQUEST_CREATE_ACCOUNT_SUCCESS"
export const REQUEST_CREATE_ACCOUNT_FAILED = "REQUEST_CREATE_ACCOUNT_FAILED"

export const INPUT_VALIDATION_ERROR = "INPUT_VALIDATION_ERROR"

export const CHANGE_PASSWORD_REQUEST = "CHANGE_PASSWORD_REQUEST"
export const CHANGE_PASSWORD_REQUEST_SUCCESS = "CHANGE_PASSWORD_REQUEST_SUCCESS"
export const CHANGE_PASSWORD_REQUEST_FAILURE = "CHANGE_PASSWORD_REQUEST_FAILURE"

export const USER_LOGOUT_REQUEST = "USER_LOGOUT_REQUEST"
export const USER_LOGOUT_REQUEST_SUCCESS = "USER_LOGOUT_REQUEST_SUCCESS"
export const USER_LOGOUT_REQUEST_FAILURE = "USER_LOGOUT_REQUEST_FAILURE"

export const GET_USER_REQUEST = "GET_USER_REQUEST"
export const GET_USER_REQUEST_SUCCESS = "GET_USER_REQUEST_SUCCESS"
export const GET_USER_REQUEST_FAILURE = "GET_USER_REQUEST_FAILURE"

export const LOGIN_REQUEST = "LOGIN_REQUEST"
export const INVALID_INPUT = "INVALID_INPUT"
export const LOGIN_REQUEST_SUCCESS = "LOGIN_REQUEST_SUCCESS"
export const LOGIN_REQUEST_FAILURE = "LOGIN_REQUEST_FAILURE"

export const FETCH_USER_LIST = "FETCH_USER_LIST"
export const FETCH_USER_LIST_SUCCESS = "FETCH_USER_LIST_SUCCESS"
export const FETCH_USER_LIST_FAILURE = "FETCH_USER_LIST_FAILURE"

export const INIT_CREATE_USER = "INIT_CREATE_USER"
export const CREATE_USER_SUCCESS = "CREATE_USER_SUCCESS"
export const CREATE_USER_FAILURE = "CREATE_USER_FAILURE"

export const INIT_UPDATE_USER = "INIT_UPDATE_USER"
export const UPDATE_USER_SUCCESS = "UPDATE_USER_SUCCESS"
export const UPDATE_USER_FAILURE = "UPDATE_USER_FAILURE"

export const FORGOT_PASSWORD_REQUEST = "FORGOT_PASSWORD_REQUEST"
export const FORGOT_PASSWORD_REQUEST_SUCCESS = "FORGOT_PASSWORD_REQUEST_SUCCESS"
export const FORGOT_PASSWORD_REQUEST_FAILURE = "FORGOT_PASSWORD_REQUEST_FAILURE"

export const DELETE_USER_SUCCESS = "DELETE_USER_SUCCESS"
export const DELETE_USER_FAILURE = "DELETE_USER_FAILURE"
export const CLEAN_DELETE_FLAG = "CLEAN_DELETE_FLAG"
export const CLEAN_CREATE_USER_DATA = "CLEAN_CREATE_USER_DATA"


export const INIT_CREATE_PROJECT_PROFILE = "INIT_CREATE_PROJECT_PROFILE"
export const CREATE_PROJECT_PROFILE_SUCCESS = "CREATE_PROJECT_PROFILE_SUCCESS"
export const CREATE_PROJECT_PROFILE_FAILURE = "CREATE_PROJECT_PROFILE_FAILURE"

export const INIT_PROJECT_PROFILE_UPDATE = "INIT_PROJECT_PROFILE_UPDATE"
export const PROJECT_PROFILE_UPDATE_SUCCESS = "PROJECT_PROFILE_UPDATE_SUCCESS"
export const PROJECT_PROFILE_UPDATE_FAILURE = "PROJECT_PROFILE_UPDATE_FAILURE"

export const INIT_PROJECT_PROFILE_DELETE = "INIT_PROJECT_PROFILE_DELETE"
export const PROJECT_PROFILE_DELETE_SUCCESS = "PROJECT_PROFILE_DELETE_SUCCESS"
export const PROJECT_PROFILE_DELETE_FAILURE = "PROJECT_PROFILE_DELETE_FAILURE"


export const INIT_CREATE_PROJECT_SCHEDULE = "INIT_CREATE_PROJECT_SCHEDULE"
export const CREATE_PROJECT_SCHEDULE_SUCCESS = "CREATE_PROJECT_SCHEDULE_SUCCESS"
export const CREATE_PROJECT_SCHEDULE_FAILURE = "CREATE_PROJECT_SCHEDULE_FAILURE"

export const INIT_PROJECT_SCHEDULE_UPDATE = "INIT_PROJECT_SCHEDULE_UPDATE"
export const PROJECT_SCHEDULEUPDATE_SUCCESS = "PROJECT_SCHEDULE_UPDATE_SUCCESS"
export const PROJECT_SCHEDULE_UPDATE_FAILURE = "PROJECT_SCHEDULEUPDATE_FAILURE"

export const INIT_PROJECT_SCHEDULE_DELETE = "INIT_PROJECT_SCHEDULE_DELETE"
export const PROJECT_SCHEDULE_DELETE_SUCCESS = "PROJECT_SCHEDULE_DELETE_SUCCESS"
export const PROJECT_SCHEDULE_DELETE_FAILURE = "PROJECT_SCHEDULE_DELETE_FAILURE"


export const INIT_CREATE_PROJECT_SCHEDULE_EXTEND_REQUEST = "INIT_CREATE_PROJECT_SCHEDULE_EXTEND_REQUEST"
export const CREATE_PROJECT_SCHEDULE_EXTEND_REQUEST_SUCCESS = "CREATE_PROJECT_SCHEDULE_EXTEND_REQUEST_SUCCESS"
export const CREATE_PROJECT_SCHEDULE_EXTEND_REQUEST_FAILURE = "CREATE_PROJECT_SCHEDULE_EXTEND_REQUEST_FAILURE"

export const INIT_PROJECT_SCHEDULE_EXTEND_REQUEST_UPDATE = "INIT_PROJECT_SCHEDULE_EXTEND_REQUEST_UPDATE"
export const PROJECT_SCHEDULEUPDATE_EXTEND_REQUEST_SUCCESS = "PROJECT_SCHEDULEUPDATE_EXTEND_REQUEST_SUCCESS"
export const PROJECT_SCHEDULE_UPDATE_EXTEND_REQUEST_FAILURE = "PROJECT_SCHEDULE_UPDATE_EXTEND_REQUEST_FAILURE"

export const INIT_PROJECT_SCHEDULE_EXTEND_REQUEST_DELETE = "INIT_PROJECT_SCHEDULE_EXTEND_REQUEST_DELETE"
export const PROJECT_SCHEDULE_DELETE_EXTEND_REQUEST_SUCCESS = "PROJECT_SCHEDULE_DELETE_EXTEND_REQUEST_SUCCESS"
export const PROJECT_SCHEDULE_DELETE_EXTEND_REQUEST_FAILURE = "PROJECT_SCHEDULE_DELETE_EXTEND_REQUEST_FAILURE"

export const INIT_CREATE_PROJECT_SUPPORT_REQUEST = "INIT_CREATE_PROJECT_SUPPORT_REQUEST"
export const CREATE_PROJECT_SUPPORT_SUCCESS = "CREATE_PROJECT_SUPPORT_SUCCESS"
export const CREATE_PROJECT_SUPPORT_FAILURE = "CREATE_PROJECT_SUPPORT_FAILURE"

export const INIT_PROJECT_SUPPORT_REQUEST_UPDATE = "INIT_PROJECT_SCHEDULE_EXTEND_REQUEST_UPDATE"
export const PROJECT_SUPPORT_UPDATE_REQUEST_SUCCESS = "PROJECT_SUPPORT_UPDATE_REQUEST_SUCCESS"
export const PROJECT_SUPPORT_UPDATE_REQUEST_FAILURE = "PROJECT_SUPPORT_UPDATE_REQUEST_FAILURE"

export const INIT_PROJECT_SUPPORT_DELETE = "INIT_PROJECT_SUPPORT_DELETE"
export const PROJECT_SUPPORT_DELETE_SUCCESS = "PROJECT_SUPPORT_DELETE_SUCCESS"
export const PROJECT_SUPPORT_DELETE_FAILURE = "PROJECT_SUPPORT_DELETE_FAILURE"

export const INIT_CREATE_PROJECT_SUPPORT_MESSAGE_REQUEST = "INIT_CREATE_PROJECT_SUPPORT_MESSAGE_REQUEST"
export const CREATE_PROJECT_SUPPORT_MESSAGE_SUCCESS = "CREATE_PROJECT_SUPPORT_MESSAGE_SUCCESS"
export const CREATE_PROJECT_SUPPORT_MESSAGE_FAILURE = "CREATE_PROJECT_SUPPORT_MESSAGE_FAILURE"

export const INIT_CREATE_NOTICE_BOARD_MESSAGE_REQUEST = "INIT_CREATE_NOTICE_BOARD_MESSAGE_REQUEST"
export const CREATE_NOTICE_BOARD_MESSAGE_SUCCESS = "CREATE_NOTICE_BOARD_MESSAGE_SUCCESS"
export const CREATE_NOTICE_BOARD_MESSAGE_FAILURE = "CREATE_NOTICE_BOARD_MESSAGE_FAILURE"

export const INIT_UPDATE_RENT_REQUEST = "INIT_UPDATE_RENT_REQUEST"
export const UPDATE_RENT_SUCCESS = "UPDATE_RENT_SUCCESS"
export const UPDATE_RENT_FAILURE = "UPDATE_RENT_FAILURE"

export const INIT_DELETE_RENT_REQUEST = "INIT_DELETE_RENT_REQUEST"
export const DELETE_RENT_SUCCESS = "DELETE_RENT_SUCCESS"
export const DELETE_RENT_FAILURE = "DELETE_RENT_FAILURE"


export const INIT_CREATE_PROJECT_IMPL_PROGRESS = "INIT_CREATE_PROJECT_IMPL_PROGRESS"
export const CREATE_PROJECT_IMPL_PROGRESS_SUCCESS = "CREATE_PROJECT_IMPL_PROGRESS_SUCCESS"
export const CREATE_PROJECT_IMPL_PROGRESS_FAILURE = "CREATE_PROJECT_IMPL_PROGRESS_FAILURE"

export const INIT_UPDATE_PROJECT_IMPL_REQUEST = "INIT_UPDATE_PROJECT_IMPL_REQUEST"
export const UPDATE_PROJECT_IMPL_SUCCESS = "UPDATE_PROJECT_IMPL_SUCCESS"
export const UPDATE_PROJECT_IMPL_FAILURE = "UPDATE_PROJECT_IMPL_FAILURE"

export const INIT_UPDATE_PROJECT_TYPE_REQUEST = "INIT_UPDATE_PROJECT_TYPE_REQUEST"
export const UPDATE_PROJECT_TYPE_SUCCESS = "UPDATE_PROJECT_TYPE_SUCCESS"
export const UPDATE_PROJECT_TYPE_FAILURE = "UPDATE_PROJECT_TYPE_FAILURE"

export const INIT_DELETE_PROJECT_IMPL_PROGRESS_REQUEST = "INIT_DELETE_PROJECT_IMPL_PROGRESS_REQUEST"
export const DELETE_PROJECT_IMPL_PROGRESS_SUCCESS = "DELETE_PROJECT_IMPL_PROGRESS_SUCCESS"
export const DELETE_PROJECT_IMPL_PROGRESS_FAILURE = "DELETE_PROJECT_IMPL_PROGRESS_FAILURE"

export const INIT_CREATE_PROJECT_PACKAGE = "INIT_CREATE_PROJECT_PACKAGE"
export const CREATE_PROJECT_PACKAGE_SUCCESS = "CREATE_PROJECT_PACKAGE_SUCCESS"
export const CREATE_PROJECT_PACKAGE_FAILURE = "CREATE_PROJECT_PACKAGE_FAILURE"

export const INIT_DELETE_PROJECT_IPACKAGE_REQUEST = "INIT_DELETE_PROJECT_IPACKAGE_REQUEST"
export const DELETE_PROJECT_PACKAGE_SUCCESS = "DELETE_PROJECT_PACKAGE_SUCCESS"
export const DELETE_PROJECT_PACKAGE_FAILURE = "DELETE_PROJECT_PACKAGE_FAILURE"


export const INIT_CREATE_PROJECT_IMPLEMENTATION_DETAILS = "INIT_CREATE_PROJECT_IMPLEMENTATION_DETAILS"
export const CREATE_PROJECT_IMPLEMENTATION_DETAILS_SUCCESS = "CREATE_PROJECT_IMPLEMENTATION_DETAILS_SUCCESS"
export const CREATE_PROJECT_IMPLEMENTATION_DETAILS_FAILURE = "CREATE_PROJECT_IMPLEMENTATION_DETAILS_FAILURE"

export const INIT_DELETE_PROJECT_IMPLEMENTATION_DETAILS_REQUEST = "INIT_DELETE_PROJECT_IMPLEMENTATION_DETAILS_REQUEST"
export const DELETE_PROJECT_IMPLEMENTATION_DETAILS_SUCCESS = "DELETE_PROJECT_IMPLEMENTATION_DETAILS_SUCCESS"
export const DELETE_PROJECT_IMPLEMENTATION_DETAILS_FAILURE = "DELETE_PROJECT_IMPLEMENTATION_DETAILS_FAILURE"




export const ADMIN_USER = "ADMIN_USER"
export const TENANT_USER = "TENANT_USER"
export const PD_USER = "PD_USER"

export const REPORT_REFRESH_TIME_INTERVAL = 30000
export const A_DAY_IN_SENCONDS = 24*60*60

export const MY_PROFILE_DETAILS_JSON = "MY_PROFILE_DETAILS_JSON"
export const USER_INPUT_VALIDATION = "USER_INPUT_VALIDATION"

export const DISPLAY_ACTIVATION_LINK = "DISPLAY_ACTIVATION_LINK"
export const SEND_ACTIVATION_MAIL = "SEND_ACTIVATION_MAIL"
export const DISPLAY_ACTIVATION_LINK_WINDOW = "DISPLAY_ACTIVATION_LINK_WINDOW"
export const INIT_DELETE_USER = "INIT_DELETE_USER"
export const CLEAN_DELETE_USER_ACTION = "CLEAN_DELETE_USER_ACTION"
export const DISABLED_USER_ACCOUNT_SUCCESS = "DISABLED_USER_ACCOUNT_SUCCESS"
export const INIT_DISABLED_USER_ACCOUNT = "INIT_DISABLED_USER_ACCOUNT"
export const DISABLED_USER_ACCOUNT_FAILURE = "DISABLED_USER_ACCOUNT_FAILURE"
export const CLEAN_DISABLED_USER_ACCOUNT = "CLEAN_DISABLED_USER_ACCOUNT"

export const INIT_SEND_ACTIVATION_MAIL = "INIT_SEND_ACTIVATION_MAIL"
export const SEND_ACTIVATION_MAIL_SUCCESS = "SEND_ACTIVATION_MAIL_SUCCESS"
export const SEND_ACTIVATION_MAIL_FAILURE = "SEND_ACTIVATION_MAIL_FAILURE"
export const CLEAN_SEND_ACTIVATION_MAIL = "CLEAN_SEND_ACTIVATION_MAIL"


export const INIT_GET_JSON_DATA = "INIT_GET_JSON_DATA"
export const FETCH_SAVE_JSON_DATA_SUCCESS = "FETCH_SAVE_JSON_DATA_SUCCESS"
export const FETCH_SAVE_JSON_DATA_FAILURE = "FETCH_SAVE_JSON_DATA_FAILURE"

export const INIT_SAVE_JSON_DATA = "INIT_SAVE_JSON_DATA"
export const SAVE_JSON_DATA_SUCCESS = "SAVE_JSON_DATA_SUCCESS"
export const SAVE_JSON_DATA_FAILURE = "SAVE_JSON_DATA_FAILURE"

export const DEVICE_PER_PAGE = 10
export const INIT_PAGE = 0

































