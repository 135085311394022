import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import PageHeader from "../../components/PageHeader";
import path from 'path'
import { TENANT_USER } from "../../utils/constants"
import RentStatsSummary from "./RentStatsSummary";
import RentList from "../Dashbord/RentList";

export default function TenantUserRentDashboard() {

  const dispatch = useDispatch();

  console.log(path.resolve(__dirname, './__test__'));
  //const dashboardData = useFetch(getDashboardDataURL("rent_tenant_dashboard"))




  return (
    <div className="dashboard-container">
      <PageHeader
        HeaderText="Rent dashboard"
        Breadcrumb={[{ name: "rent" }]}

      />
      <div className={`dashboard-container-summary`}>
        <RentStatsSummary user_type={TENANT_USER} />
      </div>
      
      <div className={`dashboard-container-summary`}>
        <RentList user_type={TENANT_USER} />
      </div>
    </div>
  )
}


