import {    
  INIT_CREATE_PROJECT_IMPLEMENTATION_DETAILS,
CREATE_PROJECT_IMPLEMENTATION_DETAILS_SUCCESS, 
CREATE_PROJECT_IMPLEMENTATION_DETAILS_FAILURE,
INIT_DELETE_PROJECT_IMPLEMENTATION_DETAILS_REQUEST,
DELETE_PROJECT_IMPLEMENTATION_DETAILS_SUCCESS,
DELETE_PROJECT_IMPLEMENTATION_DETAILS_FAILURE,
 } from "../utils/constants"

const initialState = {
  isLoading: false,
  showMessage: false,
  updatedMessageDialog: {},
  error: null,
  response: {},
  success:false,
  updateSuccess:false,
  deleteSuccess:false,
};

const projectImplementationDetailsReducer = (state = initialState, action) => {

  switch (action.type) {

    case INIT_CREATE_PROJECT_IMPLEMENTATION_DETAILS: {

      return {
        ...state,
        user: action.payload,
        isLoading: true
      };
    }
    case CREATE_PROJECT_IMPLEMENTATION_DETAILS_SUCCESS: {

      const message = {
        show: true,
        title: "Project implementation details status",
        message:action.payload.message,
        messageType: "success",
      }

      return {
        ...state,
        isLoading: false,
        response: action.payload,
        updatedMessageDialog: message,
        showMessage: true,
        success: true,


      }
    } 
    case CREATE_PROJECT_IMPLEMENTATION_DETAILS_FAILURE: {

      const message = {
        show: true,
        title: "Project implementation details status",
        message: action.payload,
        messageType: "danger",
      }

      return {
        ...state,
        isLoading: false,
        error: action.payload,
        updatedMessageDialog: message,
        showMessage: true,

      }
    }

    

    case INIT_DELETE_PROJECT_IMPLEMENTATION_DETAILS_REQUEST: {

      return {
        ...state,
        user: action.payload,
        isLoading: true
      };
    }

    case DELETE_PROJECT_IMPLEMENTATION_DETAILS_SUCCESS: {

      const message = {
        show: true,
        title: "Project implementation details delete status",
        message:"Project implementation details deleted successfully.",
        messageType: "success",
      }

      return {
        ...state,
        isLoading: false,
        response: action.payload,
        updatedMessageDialog: message,
        showMessage: true,
        deleteSuccess: true,

      }
    } 
    case DELETE_PROJECT_IMPLEMENTATION_DETAILS_FAILURE: {

      const message = {
        show: true,
        title: "Project implementation details delete status",
        message: action.payload,
        messageType: "danger",
      }

      return {
        ...state,
        isLoading: false,
        error: action.payload,
        updatedMessageDialog: message,
        showMessage: true,

      }
    }

    default:
      return state;
  }
};


export default projectImplementationDetailsReducer;
