import React from 'react'
import { ADMIN_USER } from './constants'

export default function CustomStatusInfo(props) {

    const { status, user_type } = props

    const getProjectSupportStatus = (status) => {

        var statusView
      
        if (status === 'rent_paid_and_approved') {
            statusView = (<span className="badge badge-success bg-success text-white">
                {user_type===ADMIN_USER ?"Rent paid & approved":"Paid & approved by BHTPA"}
            </span>)
      
        } else if (status === 'rent_paid_waiting_for_approval') {
            statusView = (<span className="badge badge-danger bg-info text-white">
                {user_type===ADMIN_USER ?"Paid & waiting for approval":"Paid & waiting for approval"}
            </span>)
        } else if (status === 'rent_issued') {
            statusView = (<span className="badge badge-danger bg-warning text-white">
                {user_type===ADMIN_USER ?"Rent issued":"Rent issued"}
            </span>)
        } else {
            statusView = (<span className="badge badge-danger bg-warning text-white">
                {status}
            </span>)
        }
      
        return statusView
      }

  return (
    <>{getProjectSupportStatus(status)}</>
  )
}
