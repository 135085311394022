import React from "react";
import { connect } from "react-redux";

const headingList = [
  {id: 1, name: "Projects", value: "150"},
  {id: 1, name: "Ongoing", value: "75"},
  {id: 1, name: "Completed", value: "15"},
  {id: 1, name: "On schedule", value: "25"},
  {id: 1, name: "Behind schedule", value: "10"},
  {id: 1, name: "Not started", value: "3"},
]

class ProfileIconCard extends React.Component {
  render() {
    return (
      <div className="card">
        <ul className="row profile_state list-unstyled">
          {headingList.map((data, index) =>{ return (
            <li className="col-lg-2 col-2">
            <div className="body">
              <span
                className="m-b-0 number count-to"
                data-from="0"
                data-to="2365"
                data-speed="1000"
                data-fresh-interval="700"
              >
                {data.name}
              </span>
              <h6>{data.value}</h6>
            </div>
          </li>
          )})}
          
          
          
          
        </ul>
      </div>
    );
  }
}

const mapStateToProps = ({ mailInboxReducer }) => ({});

export default connect(mapStateToProps, {})(ProfileIconCard);
