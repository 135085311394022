import React from "react";
import PageHeader from "../../components/PageHeader";
import { ADMIN_USER } from "../../utils/constants";
import RentList from "./RentList";
import RentStatsSummary from "../TenantDashboard/RentStatsSummary";

export default function RentDashboard() {

  
  return (
    <div className="dashboard-container">
      <PageHeader
        HeaderText="Rent Dashboard"
        Breadcrumb={[{ name: "rents" }]}
      />
       <div className={`dashboard-container-summary`}>
        <RentStatsSummary user_type={ADMIN_USER} />
      </div>
      <div className={`dashboard-container-summary`}>
        <RentList user_type={ADMIN_USER} />
      </div>
    </div>
  )
}


