import {    INIT_CREATE_PROJECT_SUPPORT_REQUEST,
  CREATE_PROJECT_SUPPORT_SUCCESS,
  CREATE_PROJECT_SUPPORT_FAILURE,
  INIT_PROJECT_SUPPORT_REQUEST_UPDATE,
  PROJECT_SUPPORT_UPDATE_REQUEST_SUCCESS,
  PROJECT_SUPPORT_UPDATE_REQUEST_FAILURE,
  INIT_PROJECT_SUPPORT_DELETE,
  PROJECT_SUPPORT_DELETE_SUCCESS,
  PROJECT_SUPPORT_DELETE_FAILURE,
 } from "../utils/constants"

const initialState = {
  isLoading: false,
  showMessage: false,
  updatedMessageDialog: {},
  error: null,
  response: {},
  success:false,
  updateSuccess:false,
  deleteSuccess:false,
};

const projectSupportReducer = (state = initialState, action) => {

  switch (action.type) {

    case INIT_CREATE_PROJECT_SUPPORT_REQUEST: {

      return {
        ...state,
        user: action.payload,
        isLoading: true
      };
    }
    case CREATE_PROJECT_SUPPORT_SUCCESS: {

      const message = {
        show: true,
        title: "Project support status",
        message:"Project support created successfully.",
        messageType: "success",
      }

      return {
        ...state,
        isLoading: false,
        response: action.payload,
        updatedMessageDialog: message,
        showMessage: true,
        success: true,


      }
    } 
    case CREATE_PROJECT_SUPPORT_FAILURE: {

      const message = {
        show: true,
        title: "Project support status",
        message: action.payload,
        messageType: "danger",
      }

      return {
        ...state,
        isLoading: false,
        error: action.payload,
        updatedMessageDialog: message,
        showMessage: true,

      }
    }

    case INIT_PROJECT_SUPPORT_REQUEST_UPDATE: {

      return {
        ...state,
        user: action.payload,
        isLoading: true
      };
    }

    case PROJECT_SUPPORT_UPDATE_REQUEST_SUCCESS: {

      const message = {
        show: true,
        title: "Project support update status",
        message:"Project support updated successfully.",
        messageType: "success",
      }

      return {
        ...state,
        isLoading: false,
        response: action.payload,
        updatedMessageDialog: message,
        showMessage: true,
        updateSuccess: true,

      }
    } 
    case PROJECT_SUPPORT_UPDATE_REQUEST_FAILURE: {

      const message = {
        show: true,
        title: "Project support update status",
        message: action.payload,
        messageType: "danger",
      }

      return {
        ...state,
        isLoading: false,
        error: action.payload,
        updatedMessageDialog: message,
        showMessage: true,

      }
    }

    case INIT_PROJECT_SUPPORT_DELETE: {

      return {
        ...state,
        user: action.payload,
        isLoading: true
      };
    }

    case PROJECT_SUPPORT_DELETE_SUCCESS: {

      const message = {
        show: true,
        title: "Project support delete status",
        message:"Project support deleted successfully.",
        messageType: "success",
      }

      return {
        ...state,
        isLoading: false,
        response: action.payload,
        updatedMessageDialog: message,
        showMessage: true,
        deleteSuccess: true,

      }
    } 
    case PROJECT_SUPPORT_DELETE_FAILURE: {

      const message = {
        show: true,
        title: "Project support delete status",
        message: action.payload,
        messageType: "danger",
      }

      return {
        ...state,
        isLoading: false,
        error: action.payload,
        updatedMessageDialog: message,
        showMessage: true,

      }
    }

    default:
      return state;
  }
};


export default projectSupportReducer;
