import axios from "axios";
import { BASE_HEADERS } from "../utils/baseValues";
import { createProjectImplProgressURL,
   updateProjectImplProgressURL,
   updateProjectTypeURL,
   deleteProjectImplProgressURL } from "../utils/allurls";
import {
   INIT_CREATE_PROJECT_IMPL_PROGRESS,
CREATE_PROJECT_IMPL_PROGRESS_SUCCESS, 
CREATE_PROJECT_IMPL_PROGRESS_FAILURE,
INIT_DELETE_PROJECT_IMPL_PROGRESS_REQUEST,
DELETE_PROJECT_IMPL_PROGRESS_SUCCESS,
DELETE_PROJECT_IMPL_PROGRESS_FAILURE,
INIT_UPDATE_PROJECT_IMPL_REQUEST,
UPDATE_PROJECT_IMPL_SUCCESS,
UPDATE_PROJECT_IMPL_FAILURE,
INIT_UPDATE_PROJECT_TYPE_REQUEST,
UPDATE_PROJECT_TYPE_SUCCESS,
UPDATE_PROJECT_TYPE_FAILURE
} from "../utils/constants";

export const createProjectImplName = (data) => async (dispatch) => {

   dispatch({ type: INIT_CREATE_PROJECT_IMPL_PROGRESS, payload: data })

   try {

      const headers = BASE_HEADERS()
      const response = await axios.post(createProjectImplProgressURL, data, { headers });
      console.log("create response", response)

      if (response.status === 201) {

         dispatch({ type: CREATE_PROJECT_IMPL_PROGRESS_SUCCESS, payload: response.data })
      } else {
         dispatch({ type: CREATE_PROJECT_IMPL_PROGRESS_FAILURE, payload: response.error })
      }

   } catch (error) {

      dispatch({ type: CREATE_PROJECT_IMPL_PROGRESS_FAILURE, payload: error.response.data.error })
   }
}


export const updateProjectImplToDirector = (data) => async (dispatch) => {

   dispatch({ type: INIT_UPDATE_PROJECT_IMPL_REQUEST, payload: data })

   try {

      const headers = BASE_HEADERS()
      const response = await axios.post(updateProjectImplProgressURL, data, { headers });
      console.log("update response", response)

      if (response.status === 200) {

         dispatch({ type: UPDATE_PROJECT_IMPL_SUCCESS, payload: response.data })
      } else {
         dispatch({ type: UPDATE_PROJECT_IMPL_FAILURE, payload: response.error })
      }

   } catch (error) {

      dispatch({ type: UPDATE_PROJECT_IMPL_FAILURE, payload: error.response.data.error })
   }
}


export const updateProjectType = (data) => async (dispatch) => {

   

   dispatch({ type: INIT_UPDATE_PROJECT_TYPE_REQUEST, payload: data })

   try {

      const headers = BASE_HEADERS()
      const response = await axios.post(updateProjectTypeURL, data, { headers });
      

      if (response.status === 200) {

         dispatch({ type: UPDATE_PROJECT_TYPE_SUCCESS, payload: response.data })
      } else {
         dispatch({ type: UPDATE_PROJECT_TYPE_FAILURE, payload: response.error })
      }

   } catch (error) {

      dispatch({ type: UPDATE_PROJECT_TYPE_FAILURE, payload: error.response.data.error })
   }
}


export const deleteProjectImplProgress = (projectId) => async (dispatch) => {


   dispatch({ type: INIT_DELETE_PROJECT_IMPL_PROGRESS_REQUEST, payload: projectId })

   try {

      const headers = BASE_HEADERS()
      const response = await axios.delete(deleteProjectImplProgressURL(projectId), { headers });
      console.log("delete schedule response", response)

      if (response.status === 202) {

         dispatch({ type: DELETE_PROJECT_IMPL_PROGRESS_SUCCESS, payload: response.data })
      } else {
         dispatch({ type: DELETE_PROJECT_IMPL_PROGRESS_FAILURE, payload: response.error })
      }

   } catch (error) {

      dispatch({ type: DELETE_PROJECT_IMPL_PROGRESS_FAILURE, payload: error.response.data.error })
   }
}