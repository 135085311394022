import axios from "axios";
import { BASE_HEADERS } from "../utils/baseValues";
import { createOrUpdateProjectImplementationDetailsURL,
   deleteProjectImplementationDetailsURL } from "../utils/allurls";
import {
   INIT_CREATE_PROJECT_IMPLEMENTATION_DETAILS,
CREATE_PROJECT_IMPLEMENTATION_DETAILS_SUCCESS, 
CREATE_PROJECT_IMPLEMENTATION_DETAILS_FAILURE,
INIT_DELETE_PROJECT_IMPLEMENTATION_DETAILS_REQUEST,
DELETE_PROJECT_IMPLEMENTATION_DETAILS_SUCCESS,
DELETE_PROJECT_IMPLEMENTATION_DETAILS_FAILURE,
} from "../utils/constants";

export const createOrUpdateProjectImplementationDetails = (data) => async (dispatch) => {

   dispatch({ type: INIT_CREATE_PROJECT_IMPLEMENTATION_DETAILS, payload: data })

   try {

      const headers = BASE_HEADERS()
      const response = await axios.post(createOrUpdateProjectImplementationDetailsURL, data, { headers });
      console.log("create response", response)

      if (response.status === 201 || response.status === 200) {

         dispatch({ type: CREATE_PROJECT_IMPLEMENTATION_DETAILS_SUCCESS, payload: response.data })
      } else {
         dispatch({ type: CREATE_PROJECT_IMPLEMENTATION_DETAILS_FAILURE, payload: response.error })
      }

   } catch (error) {

      dispatch({ type: CREATE_PROJECT_IMPLEMENTATION_DETAILS_FAILURE, payload: error.response.data.error })
   }
}



export const deleteProjectPackage = (id) => async (dispatch) => {


   dispatch({ type: INIT_DELETE_PROJECT_IMPLEMENTATION_DETAILS_REQUEST, payload: id })

   try {

      const headers = BASE_HEADERS()
      const response = await axios.delete(deleteProjectImplementationDetailsURL(id), { headers });
      console.log("delete package response", response)

      if (response.status === 202) {

         dispatch({ type: DELETE_PROJECT_IMPLEMENTATION_DETAILS_SUCCESS, payload: response.data })
      } else {
         dispatch({ type: DELETE_PROJECT_IMPLEMENTATION_DETAILS_FAILURE, payload: response.error })
      }

   } catch (error) {

      dispatch({ type: DELETE_PROJECT_IMPLEMENTATION_DETAILS_FAILURE, payload: error.response.data.error })
   }
}