import React, { useState } from "react";
import Button from 'react-bootstrap/Button'
import Spinner from 'react-bootstrap/Spinner'
import PageHeader from "../../components/PageHeader"
import { useHistory, useLocation } from "react-router"
import "react-datepicker/dist/react-datepicker.css";
import { useSelector, useDispatch } from "react-redux";
import { createOrUpdateProjectImplementationDetails } from "../../actions/projectImplementationDetailsAction";
import Message from "../../utils/Message";
import { userId } from "../../utils/userUtils";
import { fiscalYearList } from "../../utils/dummyDataStore"
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';


export default function CreateOrUpdateProjectImplDetails() {

  const history = useHistory();
  const location = useLocation()

  const { projectDetails } = location.state
  const dispatch = useDispatch()
  const projectImplementationDetailsReducer = useSelector(state => state.projectImplementationDetailsReducer)

  let initProjectDetails = {
    id: "",
    project_id: "",
    year: "",
    user_id: userId(),
    total_expense: "",
    till_last_fiscal_year_expense: "",
    radp_alloted_gob: "",
    alloted_gob_aid: "",
    release_gob: "",
    release_gob_aid: "",
    expended_gob: "",
    expended_gob_aid: "",
    current_year_actual_progress: "",
    accumulated_actual_progress: ""

  }

  if (projectDetails) {
    initProjectDetails = {
      id: projectDetails.id,
      project_id: projectDetails.project_impl_id,
      year: projectDetails.year,
      user_id: userId(),
      total_expense: projectDetails.total_expense,
      till_last_fiscal_year_expense: projectDetails.till_last_fiscal_year_expense,
      radp_alloted_gob: projectDetails.radp_alloted_gob,
      alloted_gob_aid: projectDetails.alloted_gob_aid,
      release_gob: projectDetails.release_gob,
      release_gob_aid: projectDetails.release_gob_aid,
      expended_gob: projectDetails.expended_gob,
      expended_gob_aid: projectDetails.expended_gob_aid,
      current_year_actual_progress: projectDetails.current_year_actual_progress,
      accumulated_actual_progress: projectDetails.accumulated_actual_progress

    }
  }


  const [packageData, setPackageData] = useState(initProjectDetails)
 
  const handleOnchange = (e) => {
    const fieldName = e.target.name
    const fieldValue = e.target.value
    setPackageData({
      ...packageData,
      [fieldName]: fieldValue
    })

    console.log("packageData : ", packageData)
  }

  const fiscalYearChange = (event) => {
   // setProjectId(event.target.value);
   console.log("event.target.value",event.target.value)
  
   setPackageData({
      ...packageData,
      year: event.target.value
    })
  };

  const resetPageData = () => {
    //setProjectProfileData(initProjectProfile)
  }


  const submitData = (e) => {
    e.preventDefault();

    dispatch(createOrUpdateProjectImplementationDetails(packageData))

  }

  return (
    <div> {
      (<div className="body">
        <PageHeader
          HeaderText={`Save project implementation`}
          Breadcrumb={[
            { name: "project-implementation-details", navigate: "" },
            { name: "save", navigate: "" },
          ]}
        />
        <h6>* All amount figure in lakh taka</h6>
        <div className="row clearfix">
          <div className="col-lg-6 col-md-12">
            
            <div className="form-group">
              <span>Fiscal year</span>
              <Select
                className="form-control"
                labelId="demo-select-small form-group"
                id="demo-select-small"
                name={"year"}
                value={packageData.year}
                label="Select fiscal year"
                onChange={(event)=>{
                  setPackageData({
                    ...packageData,
                    year: event.target.value
                  })
                       console.log("menu event:",event)

                }}
              >
                <MenuItem className="form-group" value="Select fiscal year">
                  <em>Select fiscal year</em>
                </MenuItem>

                {fiscalYearList && (fiscalYearList()).map((item, index)=> {
                  return (<MenuItem key={item.year} value={item.year}>{item.fiscalYearFormat}</MenuItem>)
                })}
              </Select>
            </div>
            <div className="form-group">
              <span>Total project expense</span>
              <input
                className="form-control"
                placeholder=""
                name="total_expense"
                value={packageData.total_expense}
                type="text"
                onChange={(e) => handleOnchange(e)}
              />
            </div>
            <div className="form-group">
              <span>Expense untill previous fiscal year</span>
              <input
                className="form-control"
                placeholder=""
                name="till_last_fiscal_year_expense"
                value={packageData.till_last_fiscal_year_expense}
                type="text"
                onChange={(e) => handleOnchange(e)}
              />
            </div>

            <div className="form-group">
              <span>RADP alloted GOB</span>
              <input
                className="form-control"
                placeholder=""
                name="radp_alloted_gob"
                value={packageData.radp_alloted_gob}
                type="text"
                onChange={(e) => handleOnchange(e)}
              />
            </div>

            <div className="form-group">
              <span>RADP alloted aid</span>
              <input
                className="form-control"
                placeholder=""
                name="alloted_gob_aid"
                value={packageData.alloted_gob_aid}
                type="text"
                onChange={(e) => handleOnchange(e)}
              />
            </div>

            <div className="form-group">
              <span>Released GOB</span>
              <input
                className="form-control"
                placeholder=""
                name="release_gob"
                value={packageData.release_gob}
                type="text"
                onChange={(e) => handleOnchange(e)}
              />
            </div>

            <div className="form-group">
              <span>Released aid</span>
              <input
                className="form-control"
                placeholder=""
                name="release_gob_aid"
                value={packageData.release_gob_aid}
                type="text"
                onChange={(e) => handleOnchange(e)}
              />
            </div>

            <div className="form-group">
              <span>Expended GOB</span>
              <input
                className="form-control"
                placeholder=""
                name="expended_gob"
                value={packageData.expended_gob}
                type="text"
                onChange={(e) => handleOnchange(e)}
              />
            </div>

            <div className="form-group">
              <span>Expended aid</span>
              <input
                className="form-control"
                placeholder=""
                name="expended_gob_aid"
                value={packageData.expended_gob_aid}
                type="text"
                onChange={(e) => handleOnchange(e)}
              />
            </div>

            <div className="form-group">
              <span>Accumulated total financial progress(%)</span>
              <input
                className="form-control"
                placeholder=""
                name="current_year_actual_progress"
                value={packageData.current_year_actual_progress}
                type="text"
                onChange={(e) => handleOnchange(e)}
              />
            </div>

            <div className="form-group">
              <span>Accumulated total physical progress till now(%)</span>
              <input
                className="form-control"
                placeholder=""
                name="accumulated_actual_progress"
                value={packageData.accumulated_actual_progress}
                type="text"
                onChange={(e) => handleOnchange(e)}
              />
            </div>

            <div className="form-group">

              {(projectImplementationDetailsReducer.showMessage && !projectImplementationDetailsReducer.isLoading) && <Message data={projectImplementationDetailsReducer.updatedMessageDialog} />}

              {/* {initProjectSchedule && resetPageData()} */}
            </div>


            <div>

              <Button onClick={(e) => { submitData(e) }} variant="primary">
                {
                  projectImplementationDetailsReducer.loading && <Spinner
                    as="span"
                    animation="grow"
                    size="sm"
                    role="status"
                    aria-hidden="true"
                  />
                }

                {projectImplementationDetailsReducer.loading ? "Saving..." : "Save"}
              </Button>

              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;

              <Button onClick={(e) => {
                history.push({ pathname: "pd-user-dashboard" })
              }} variant="secondary">

                {"Back to project list"}
              </Button>
              <br></br>
              <br></br>
              <br></br>

            </div>

          </div>


        </div>
      </div>)}


    </div>
  )
}
