import React, { useEffect, useMemo, useState } from "react";
import PageHeader from "../../components/PageHeader";
import { useFetch } from "../../customHooks/fetchData"
import ProjectScheduleList from "./ProjectScheduleList";
import { ADMIN_USER } from "../../utils/constants";
import ScheduleStatsSummary from "../TenantDashboard/ScheduleStatsSummary";

export default function ProjectScheduleDashboard() {

  return (
    <div className="dashboard-container">
      <PageHeader
        HeaderText="Schedule dashboard"
        Breadcrumb={[{ name: "schedule" }]}
      />
       <div className={`dashboard-container-summary`}>
        <ScheduleStatsSummary user_type={ADMIN_USER} />
      </div>
       <div className={`dashboard-container-summary`}>
        <ProjectScheduleList user_type={ADMIN_USER} />
      </div>
     
    </div>
  )
}