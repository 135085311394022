import React, { useState } from "react";
import PageHeader from "../../components/PageHeader";
import ContactCard from "../../components/ContactCard";
import { useFetch } from "../../customHooks/fetchData"
import { getAllBhtpaContractPersonURL } from "../../utils/allurls"
import { useHistory } from "react-router-dom";


export default function ContactDashboard() {

  const history = useHistory()
  const constList = useFetch(getAllBhtpaContractPersonURL)
  console.log("constList",constList)


  const addBhtpaContactPage = () => {
    history.push("/add-bhtpa-contact")
  }

  return (
    <div style={{ flex: 1 }}>
        <div style={{ opacity: true ? 1 : 1 }}>
          <div className="ng-star-inserted">
            <div className="container-fluid">
              <PageHeader
                HeaderText="Contact"
                Breadcrumb={[{ name: "App" }, { name: "Contact" }]}
              />
              <div className="row clearfix">
                { constList.success && (constList.data.data).map((contactUser, i) => {
                  return (
                    <ContactCard
                      key={"key" + i}
                      data={contactUser}
    
                    />
                  );
                })}
                <div className="col-lg-3 col-md-6 col-sm-12">
                  <div className="card">
                    <div className="body text-center">
                      <div className="p-t-65 p-b-65">
                        <h6>Add New Contact</h6>
                        <button
                          className="btn btn-outline-primary"
                          onClick={() => {
                            addBhtpaContactPage()
                          }}
                          data-target="#addcontact"
                          data-toggle="modal"
                          type="button"
                        >
                          <i className="fa fa-plus-circle"></i>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
  )
}

