import React from 'react'
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import { CardActionArea } from '@mui/material';
import { dateStringFull} from "../../utils/DateUtils"

export default function NoticeboardMessage(props) {

    const { noticeBoardObject } = props

  return (
    <Card sx={{ maxWidth: "100%" }}>
    <CardActionArea>
      <CardContent>
        {dateStringFull(noticeBoardObject.time)}
        <Typography gutterBottom variant="h6" component="div">
        {noticeBoardObject.notice_subject}
        </Typography>
        
        {/* <Typography variant="body1" color="text.secondary">
        
        </Typography> */}
        <Typography variant="body2" color="text.secondary">
        {noticeBoardObject.notice_details}
        </Typography>
      </CardContent>
    </CardActionArea>
  </Card>
    
  )
}
