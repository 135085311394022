import React, { useEffect, useState } from "react";
import { userObj } from '../../utils/userUtils'
import Button from 'react-bootstrap/Button'
import Spinner from 'react-bootstrap/Spinner'
import Message from "../../utils/Message";
import { useSelector, useDispatch } from "react-redux";
import { userProfileUpdate } from "../../actions/userProfileUpdateAction";


export default function ProfileV1Setting() {

  const initUserData = userObj()
  const dispatch = useDispatch()
  const [userData, setUserData] = useState({ ...initUserData })
  const message = {
    show: false,
    title: "",
    message: "",
    messageType: "danger",
  }
  const [messageDialog, setMessageDialog] = useState(message)
  const updatedProfile = useSelector(state => state.userProfileUpdate)


  const handleOnchange = (e) => {
    const fieldName = e.target.name
    const fieldValue = e.target.value
    setUserData({
      ...userData,
      [fieldName]: fieldValue
    })
  }


  useEffect(() => {

    if (updatedProfile.showMessage) {
      setMessageDialog({
        ...messageDialog,
        ...updatedProfile.updatedMessageDialog
      })
    }

  }, [updatedProfile])


  const submitUpdate = (e) => {
    e.preventDefault();
    dispatch(userProfileUpdate(userData))
  }

  return (
    <><div className="body">
        <h6>Basic Information</h6>
        <div className="row clearfix">
          <div className="col-lg-6 col-md-12">
            <div className="form-group">
              <span>Email</span>
              <input
                className="form-control"
                placeholder="Email*"
                readOnly 
                name="email"
                value={userData.email}
                type="text"
                onChange={(e) => handleOnchange(e)}
              />
            </div>
            <div className="form-group">
            <span>Name</span>
              <input
                className="form-control"
                placeholder="Name"
                name="name"
                value={userData.name}
                type="text"
                onChange={(e) => handleOnchange(e)}
              />
            </div>

            <div className="form-group">
            <span>Description</span>
              <input
                className="form-control"
                placeholder="Description"
                name="description"
                value={userData.description}
                type="text"
                onChange={(e) => handleOnchange(e)}
              />
            </div>

            <div className="form-group">

              {(messageDialog.show && !updatedProfile.isLoading) && <Message data={messageDialog} />}

            </div>

            <div >

              <Button onClick={(e) => { submitUpdate(e) }} variant="primary">
                {
                  updatedProfile.isLoading && <Spinner
                    as="span"
                    animation="grow"
                    size="sm"
                    role="status"
                    aria-hidden="true"
                  />
                }

                {updatedProfile.isLoading ? "Loading..." : "Update"}
              </Button>

            </div>
          </div>
        </div>
      </div>


    </>
  )
}
