import React, { useEffect, useMemo, useState } from "react";
import PageHeader from "../../components/PageHeader";
import TenantList from "./TenantList"


export default function TenantAdminDashboard() {
  
  return (
    <div className="dashboard-container">
      <PageHeader
        HeaderText="Investor Dashboard"
        Breadcrumb={[{ name: "investor" }]}
      />
      <div className={`device-main-container`}>
        { <TenantList  /> }
      </div>
    </div>
  )
}


