import { combineReducers } from "redux";
import loginReducer from "./loginReducer";
import forgetPasswordReducer from "./forgotPasswordReducer";
import userReducer from "./userReducer";
import changePassowrdReducer from "./chanagePasswordReducer";
import userLogoutReducer from "./userLogoutReducer";
import userProfileUpdateReducer from "./userProfileUpdateReducer"
import navigationReducer from "./navigationReducer";
import createNewAccountReducer from "./createUserReducer";
import projectProfileReducer from "./projectProfileReducer";
import projectScheduleReducer from "./projectScheduleReducer";
import projectScheduleExtendRequestReducer from "./projectScheduleExtendRequestReducer";
import projectSupportReducer from "./projectSupportReducer"
import projectSupportMessageReducer from "./projectSupportMessageReducer"
import noticeBoardReducer from "./noticeBoardReducer"
import rentReducer from "./rentReducer"
import projectImplNameReducer from "./projectImplNameReducer"
import projectPackageReducer from "./projectPackageReducer"
import projectImplementationDetailsReducer from "./projectImplementationDetailsReducer"

export default combineReducers({
  loginReducer: loginReducer,
  forgetPasswordReducer: forgetPasswordReducer,
  userReducer: userReducer,
  userProfileUpdate: userProfileUpdateReducer,
  navigationReducer: navigationReducer,
  logout: userLogoutReducer,
  changePassowrdReducer: changePassowrdReducer,
  createNewAccountReducer: createNewAccountReducer,
  projectProfileReducer: projectProfileReducer,
  projectScheduleReducer: projectScheduleReducer,
  projectScheduleExtendRequestReducer: projectScheduleExtendRequestReducer,
  projectSupportReducer: projectSupportReducer,
  projectSupportMessageReducer: projectSupportMessageReducer,
  noticeBoardReducer: noticeBoardReducer,
  rentReducer: rentReducer,
  projectImplNameReducer: projectImplNameReducer,
  projectPackageReducer: projectPackageReducer,
  projectImplementationDetailsReducer: projectImplementationDetailsReducer
  
});
