import React, { useState } from "react";
import Button from 'react-bootstrap/Button'
import Spinner from 'react-bootstrap/Spinner'
import PageHeader from "../../components/PageHeader"
import { useHistory, useLocation } from "react-router"
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useSelector, useDispatch } from "react-redux";
import { createProjectImplName } from "../../actions/projectImplNameAction";
import Message from "../../utils/Message";
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';

const projectTypeList = [{id: 1, project_type: "Running project"},{id: 2, project_type: "Operational project"}]


export default function CreateProjectImplName() {

  const history = useHistory();
  const location = useLocation()
  const dispatch = useDispatch()
  const projectImplNameReducer = useSelector(state => state.projectImplNameReducer)

  let initPackage = {
    project_name: "",
    start_date: "",
    end_date: "",
    status: 1,
    type: 1
  }


  const [projectNameData, setProjectNameData] = useState(initPackage)
  const [package_start_date_picker, set_package_start_date_picker] = useState(new Date());
  const [package_end_date_picker, set_package_end_date_picker] = useState(new Date());

  const handleOnchange = (e) => {
    const fieldName = e.target.name
    const fieldValue = e.target.value
    setProjectNameData({
      ...projectNameData,
      [fieldName]: fieldValue
    })

    console.log("projectNameData : ", projectNameData)
  }

  const projectTypeChange = (event) => {
    const id = event.target.value
    setProjectNameData({
      ...projectNameData,
      type: id
    })


  };

  const resetPageData = () => {
    //setProjectProfileData(initProjectProfile)
  }


  const submitData = (e) => {
    e.preventDefault();

   dispatch(createProjectImplName(projectNameData))

  }

  return (
    <div> {
      (<div className="body">
        <PageHeader
          HeaderText={`Create project name`}
          Breadcrumb={[
            { name: "project-name", navigate: "" },
            { name: "create", navigate: "" },
          ]}
        />
        <h6>Create project name</h6>
        <div className="row clearfix">
          <div className="col-lg-6 col-md-12">
            
            <div className="form-group">
              <span>Project name</span>
              <input
                className="form-control"
                placeholder=""
                name="project_name"
                value={projectNameData.project_name}
                type="text"
                onChange={(e) => handleOnchange(e)}
              />
            </div>

            

            <div className="form-group">
              <span>Project type</span>
              <Select
                className="form-control"
                labelId="demo-select-small form-group"
                id="demo-select-small"
                value={projectNameData.id}
                label="Select project type"
                onChange={projectTypeChange}
              >
       

                {projectTypeList.map((item, index)=> {
                  return (<MenuItem value={item.id}>{item.project_type}</MenuItem>)
                })}
              </Select>
            </div>
           
            


            <div className="form-group">
              <span>Project term start</span>
              <DatePicker dateFormat="yyyy-MM"
              showMonthYearPicker
              showFullMonthYearPicker
              showFourColumnMonthYearPicker
               name="start_date" className="form-control" selected={package_start_date_picker}
                onChange={(date) => {
                  set_package_start_date_picker(date)
                  setProjectNameData((state) => {
                    return {
                      ...state,
                      start_date: `${date}-02`
                    }
                  })
                }
                }
              />
            </div>
            <div className="form-group">
              <span>Project term end</span>
              <DatePicker 
              
              name="end_date" 
              className="form-control" 
              selected={package_end_date_picker}
              dateFormat="yyyy-MM"
              showMonthYearPicker
              showFullMonthYearPicker
              showFourColumnMonthYearPicker
              onChange={(date) => {
                  set_package_end_date_picker(date)
                  setProjectNameData((state) => {
                    return {
                      ...state,
                      end_date: `${date}-02`
                    }
                  })
                }
                }
              />
            </div>

            <div className="form-group">

              {(projectImplNameReducer.showMessage && !projectImplNameReducer.isLoading) && <Message data={projectImplNameReducer.updatedMessageDialog} />}

              {/* {initProjectSchedule && resetPageData()} */}
            </div>


            <div>

              <Button onClick={(e) => { submitData(e) }} variant="primary">
                {
                  projectImplNameReducer.loading && <Spinner
                    as="span"
                    animation="grow"
                    size="sm"
                    role="status"
                    aria-hidden="true"
                  />
                }

                {projectImplNameReducer.loading ? "Saving..." : "Save"}
              </Button>

              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;

              <Button onClick={(e) => {
                 history.push({pathname:"project-implemention-name-list"})
                 }} variant="secondary">

                {"Back to project name list"}
              </Button>
              <br></br>
              

            </div>

          </div>


        </div>
      </div>)}


    </div>
  )
}
