import React, { useState } from "react";
import Button from 'react-bootstrap/Button'
import Spinner from 'react-bootstrap/Spinner'
import PageHeader from "../../components/PageHeader"
import { useHistory } from "react-router"
import "react-datepicker/dist/react-datepicker.css";
import { useSelector, useDispatch } from "react-redux";
import { createNoticeboardRequest } from "../../actions/noticeboardAction";
import { userId } from "../../utils/userUtils";
import Message from "../../utils/Message";
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import { useFetch } from "../../customHooks/fetchData"
import { getAllUsersURL } from "../../utils/allurls"
import { TENANT_USER } from "../../utils/constants";

export default function CreateNoticeboard() {

  const history = useHistory();
  const dispatch = useDispatch()
  const noticeBoardReducer = useSelector(state => state.noticeBoardReducer)

  const initCreateNotice = {
    user_id: userId(),
    notice_subject: "",
    notice_details: "",
    receiver_id: ""
  }

  const [noticeBoardData, setNoticeBoardData] = useState(initCreateNotice)
  const [receiverIdData, setRecieverIdData] = useState()

  const tenantList = useFetch(getAllUsersURL(TENANT_USER))


  const handleOnchange = (e) => {
    const fieldName = e.target.name
    const fieldValue = e.target.value
    setNoticeBoardData({
      ...noticeBoardData,
      [fieldName]: fieldValue
    })

    console.log("noticeBoardData : ", noticeBoardData)
  }

  const receiverIdChange = (event) => {
    const receiver_id = event.target.value
    setRecieverIdData(receiver_id);
  
    setNoticeBoardData({
      ...noticeBoardData,
      receiver_id: receiver_id
    })


  };


  const resetPageData = () => {
    //setNoticeBoardData(initdata)
  }


  const submitData = (e) => {
    e.preventDefault();
    dispatch(createNoticeboardRequest(noticeBoardData))

  }

  return (
    <div> {
      (<div className="body">
        <PageHeader
          HeaderText={`Create notice board`}
          Breadcrumb={[
          
            { name: "create", navigate: "" },
          ]}
        />
        <h6></h6>
        <div className="row clearfix">
          <div className="col-lg-6 col-md-12">
            
    
            <div className="form-group">
              <span>Notice subject</span>
              <input
                className="form-control"
                placeholder=""
                name="notice_subject"
                value={noticeBoardData.notice_subject}
                type="text"
                onChange={(e) => handleOnchange(e)}
              />
            </div>
            <div className="form-group">
              <span>Notice details</span>
              <textarea 
                style={{height: '180px'}}
                className="form-control"
                placeholder=""
                name="notice_details"
                value={noticeBoardData.notice_details}
                type="text"
                onChange={(e) => handleOnchange(e)}
              />
            </div>

            <div className="form-group ">

<span>Select specific investor account user only if send this investor(Optional)</span>
{/* <InputLabel id="demo-select-small form-group">Select project</InputLabel> */}
  <Select
    className="form-control"
    labelId="demo-select-small form-group"
    id="demo-select-small"
    value={receiverIdData}
    label="Select contact person"
    onChange={receiverIdChange}
  >
    <MenuItem className="form-group" value="Select project">
      <em>Select investor account user</em>
    </MenuItem>

    {tenantList && (tenantList.success && tenantList.data.data.map((item, index)=> {
      return (<MenuItem value={item.id}>{item.name}</MenuItem>)
    }))}
  </Select>
</div>

            <div className="form-group">

              {(noticeBoardReducer.showMessage && !noticeBoardReducer.isLoading) && <Message data={noticeBoardReducer.updatedMessageDialog} />}

    
            </div>


            <div>

              <Button onClick={(e) => { submitData(e) }} variant="primary">
                {
                  noticeBoardReducer.loading && <Spinner
                    as="span"
                    animation="grow"
                    size="sm"
                    role="status"
                    aria-hidden="true"
                  />
                }

                {noticeBoardReducer.loading ? "Submitting..." : "Create notice"}
              </Button>

              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;

              <Button onClick={(e) => { history.push("notice-board-dashboard") }} variant="secondary">

                {"Back to notice board list"}
              </Button>
              <br></br>
              <br></br>
              <br></br>

            </div>

          </div>


        </div>
      </div>)}


    </div>
  )
}
