import {       
  INIT_UPDATE_RENT_REQUEST,
  UPDATE_RENT_SUCCESS,
  UPDATE_RENT_FAILURE,
  INIT_DELETE_RENT_REQUEST,
  DELETE_RENT_SUCCESS,
  DELETE_RENT_FAILURE,
 } from "../utils/constants"

const initialState = {
  isLoading: false,
  showMessage: false,
  updatedMessageDialog: {},
  error: null,
  response: {},
  success:false,
  updateSuccess:false,
  deleteSuccess:false,
};

const rentReducer = (state = initialState, action) => {

  switch (action.type) {

    case INIT_UPDATE_RENT_REQUEST: {

      return {
        ...state,
        user: action.payload,
        isLoading: true
      };
    }

    case UPDATE_RENT_SUCCESS: {

      const message = {
        show: true,
        title: "Rent update status",
        message:"Rent updated successfully.",
        messageType: "success",
      }

      return {
        ...state,
        isLoading: false,
        response: action.payload,
        updatedMessageDialog: message,
        showMessage: true,
        updateSuccess: true,

      }
    } 
    case UPDATE_RENT_FAILURE: {

      const message = {
        show: true,
        title: "Rent update status",
        message: action.payload,
        messageType: "danger",
      }

      return {
        ...state,
        isLoading: false,
        error: action.payload,
        updatedMessageDialog: message,
        showMessage: true,

      }
    }

    case INIT_DELETE_RENT_REQUEST: {

      return {
        ...state,
        user: action.payload,
        isLoading: true
      };
    }

    case DELETE_RENT_SUCCESS: {

      const message = {
        show: true,
        title: "Rent delete status",
        message:"Rent deleted successfully.",
        messageType: "success",
      }

      return {
        ...state,
        isLoading: false,
        response: action.payload,
        updatedMessageDialog: message,
        showMessage: true,
        deleteSuccess: true,

      }
    } 
    case DELETE_RENT_FAILURE: {

      const message = {
        show: true,
        title: "Rent delete status",
        message: action.payload,
        messageType: "danger",
      }

      return {
        ...state,
        isLoading: false,
        error: action.payload,
        updatedMessageDialog: message,
        showMessage: true,

      }
    }

    default:
      return state;
  }
};


export default rentReducer;
