import React from "react";
import PageHeader from "../../components/PageHeader";
import ProjectScheduleExtendRequestList from "./ProjectScheduleExtendRequestList";
import { ADMIN_USER } from "../../utils/constants";
import ExtensionStatsSummary from "../TenantDashboard/ExtensionStatsSummary";

export default function ProjectScheduleExtendDashboard() {

  return (
    <div className="dashboard-container">
      <PageHeader
        HeaderText="Schedule extend dashboard"
        Breadcrumb={[{ name: "schedule-extend" }]}
      />
      <div className={`dashboard-container-summary`}>
      <ExtensionStatsSummary user_type={ADMIN_USER} />
      </div>
      
      <div className={`dashboard-container-summary`}>
        <ProjectScheduleExtendRequestList user_type={ADMIN_USER} />
      </div>
    </div>
  )
}