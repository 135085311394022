import {  REQUEST_CREATE_ACCOUNT,
  REQUEST_CREATE_ACCOUNT_SUCCESS,
  REQUEST_CREATE_ACCOUNT_FAILED
 } from "../utils/constants"

const initialState = {
  isLoading: false,
  showMessage: false,
  updatedMessageDialog: {},
  error: null,
  response: {},
  success:false
};

const createNewAccountReducer = (state = initialState, action) => {

  switch (action.type) {

    case REQUEST_CREATE_ACCOUNT: {

      return {
        ...state,
        user: action.payload,
        isLoading: true
      };
    }
    case REQUEST_CREATE_ACCOUNT_SUCCESS: {

      const message = {
        show: true,
        title: "Create account status",
        message:"New account created successfully.",
        messageType: "success",
      }

      return {
        ...state,
        isLoading: false,
        response: action.payload,
        updatedMessageDialog: message,
        showMessage: true,
        success: true,
      }
    } 
    case REQUEST_CREATE_ACCOUNT_FAILED: {

      const message = {
        show: true,
        title: "Create account status",
        message: action.payload,
        messageType: "danger",
      }

      return {
        ...state,
        isLoading: false,
        error: action.payload,
        updatedMessageDialog: message,
        showMessage: true,

      }
    }

    default:
      return state;
  }
};


export default createNewAccountReducer;
