import { INIT_CREATE_PROJECT_IMPL_PROGRESS,
  CREATE_PROJECT_IMPL_PROGRESS_SUCCESS, 
  CREATE_PROJECT_IMPL_PROGRESS_FAILURE,
  INIT_DELETE_PROJECT_IMPL_PROGRESS_REQUEST,
  DELETE_PROJECT_IMPL_PROGRESS_SUCCESS,
  DELETE_PROJECT_IMPL_PROGRESS_FAILURE,
  INIT_UPDATE_PROJECT_IMPL_REQUEST,
  UPDATE_PROJECT_IMPL_SUCCESS,
  UPDATE_PROJECT_IMPL_FAILURE,
  INIT_UPDATE_PROJECT_TYPE_REQUEST,
UPDATE_PROJECT_TYPE_SUCCESS,
UPDATE_PROJECT_TYPE_FAILURE
 } from "../utils/constants"

const initialState = {
  isLoading: false,
  showMessage: false,
  updatedMessageDialog: {},
  error: null,
  response: {},
  success:false,
  updateSuccess:false,
  typeSuccess:false,
  deleteSuccess:false,
};

const projectImplNameReducer = (state = initialState, action) => {

  switch (action.type) {

    case INIT_CREATE_PROJECT_IMPL_PROGRESS: {

      return {
        ...state,
        user: action.payload,
        isLoading: true
      };
    }
    case CREATE_PROJECT_IMPL_PROGRESS_SUCCESS: {

      const message = {
        show: true,
        title: "Project status",
        message:"Project name created successfully.",
        messageType: "success",
      }

      return {
        ...state,
        isLoading: false,
        response: action.payload,
        updatedMessageDialog: message,
        showMessage: true,
        success: true,


      }
    } 
    case CREATE_PROJECT_IMPL_PROGRESS_FAILURE: {

      const message = {
        show: true,
        title: "Project status",
        message: action.payload,
        messageType: "danger",
      }

      return {
        ...state,
        isLoading: false,
        error: action.payload,
        updatedMessageDialog: message,
        showMessage: true,

      }
    }

    case INIT_UPDATE_PROJECT_IMPL_REQUEST: {

      return {
        ...state,
        user: action.payload,
        isLoading: true
      };
    }

    case UPDATE_PROJECT_IMPL_SUCCESS: {

      const message = {
        show: true,
        title: "Project status",
        message:"Project name updated successfully.",
        messageType: "success",
      }

      return {
        ...state,
        isLoading: false,
        response: action.payload,
        updatedMessageDialog: message,
        showMessage: true,
        updateSuccess: true,

      }
    } 
    case UPDATE_PROJECT_IMPL_FAILURE: {

      const message = {
        show: true,
        title: "Project name update status",
        message: action.payload,
        messageType: "danger",
      }

      return {
        ...state,
        isLoading: false,
        error: action.payload,
        updatedMessageDialog: message,
        showMessage: true,

      }
    }


    case INIT_UPDATE_PROJECT_TYPE_REQUEST: {

      return {
        ...state,
        user: action.payload,
        isLoading: true
      };
    }

    case UPDATE_PROJECT_TYPE_SUCCESS: {

      const message = {
        show: true,
        title: "Project status",
        message:"Project type updated successfully.",
        messageType: "success",
      }

      return {
        ...state,
        isLoading: false,
        response: action.payload,
        updatedMessageDialog: message,
        showMessage: true,
        typeSuccess: true,

      }
    } 
    case UPDATE_PROJECT_TYPE_FAILURE: {

      const message = {
        show: true,
        title: "Project type update status",
        message: action.payload,
        messageType: "danger",
      }

      return {
        ...state,
        isLoading: false,
        error: action.payload,
        updatedMessageDialog: message,
        showMessage: true,

      }
    }

    case INIT_DELETE_PROJECT_IMPL_PROGRESS_REQUEST: {

      return {
        ...state,
        user: action.payload,
        isLoading: true
      };
    }

    case DELETE_PROJECT_IMPL_PROGRESS_SUCCESS: {

      const message = {
        show: true,
        title: "Project name delete status",
        message:"Project name deleted successfully.",
        messageType: "success",
      }

      return {
        ...state,
        isLoading: false,
        response: action.payload,
        updatedMessageDialog: message,
        showMessage: true,
        deleteSuccess: true,

      }
    } 
    case DELETE_PROJECT_IMPL_PROGRESS_FAILURE: {

      const message = {
        show: true,
        title: "Project name delete status",
        message: action.payload,
        messageType: "danger",
      }

      return {
        ...state,
        isLoading: false,
        error: action.payload,
        updatedMessageDialog: message,
        showMessage: true,

      }
    }

    default:
      return state;
  }
};


export default projectImplNameReducer;
