import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import PageHeader from "../../components/PageHeader";
import { createContractPersonURL } from "../../utils/allurls"
import axios from 'axios'

export default function AddBhtpaContact() {

  const fieldsValue = {
    name: "",
    title: "",
    status: 1,
    contact_email: "",
    contact_phone: "",
  }

  const initLoaderMessage = {
    isLoading: false,
    message: ""
  }
  const history = useHistory()
  const [file, setFile] = useState()
  const [loaderMessage, setLoaderMessage] = useState(initLoaderMessage)
  const [fields, setFields] = useState(fieldsValue)

  const goBack = () => {
    history.push("/contacts-person")
  }

  function handleChange(event) {
    setFile(event.target.files[0])
  }

  const onChangeFields = (e) => {

    const fieldName = e.target.name
    const fieldValue = e.target.value
    setFields({
      ...fields,
      [fieldName]: fieldValue
    })
  }

  const submitContacts = (event) => {

    event.preventDefault()

    setLoaderMessage({
      isLoading: true,
      message: ""
    })

    const url = createContractPersonURL;
    const formData = new FormData();
    formData.append('file', file);
    formData.append('fileName', file.name);
    formData.append('name', fields.name);
    formData.append('title', fields.title);
    formData.append('status', fields.status);
    formData.append('contact_email', fields.contact_email);
    formData.append('contact_phone', fields.contact_phone);
    const config = {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    };
    axios.post(url, formData, config).then((response) => {
      console.log(response.data);
      setLoaderMessage({
        isLoading: false,
        message: "Contact added successfully"
      })
      setFields(fieldsValue)
    });
  }
  return (

    <div className="dashboard-container">
      <div className="container-fluid">
        <PageHeader
          HeaderText="Add contact"
          Breadcrumb={[{ name: "Bhtpa contacts" }, { name: "Add contact" }]}
        />
        <div className="body">
          <div className="row clearfix">
            <div className="col-12">
              <div className="form-group">
                <input
                  type="text"
                  name="name"
                  value={fields.name}
                  onChange={onChangeFields}
                  className="form-control"
                  placeholder="Full name"
                />
              </div>
            </div>

            <div className="col-12">
              <div className="form-group">
                <input
                  type="text"
                  name="title"
                  value={fields.title}
                  onChange={onChangeFields}
                  className="form-control"
                  placeholder="Job Title"
                />
              </div>
            </div>
            <div className="col-12">
              <div className="form-group">
                <input
                  type="number"
                  name="contact_phone"
                  value={fields.contact_phone}
                  onChange={onChangeFields}
                  className="form-control"
                  placeholder="Contact phone"
                />
              </div>
            </div>
            <div className="col-12">
              <div className="form-group">
                <input
                  type="text"
                  name="contact_email"
                  value={fields.contact_email}
                  onChange={onChangeFields}
                  className="form-control"
                  placeholder="Contact email"
                />
              </div>
            </div>
            <div className="col-12">
              <div className="form-group">
                <input
                  type="file"
                  onChange={handleChange}
                  className="form-control-file"
                  id="exampleInputFile"
                  aria-describedby="fileHelp"
                />
                <small id="fileHelp" className="form-text text-muted">
                  Upload profile picture(optional)
                </small>
              </div>

            </div>

            <div className="col-12">
              <div className="form-group">

                <small id="fileHelp" className="form-text text-muted">
                  {loaderMessage.isLoading && "Adding contact..."} <br></br>
                  {loaderMessage.message}
                </small>
              </div>

            </div>

          </div>
        </div>
        <div className="modal-footer">
          <button type="button" className="btn btn-primary"
            onClick={submitContacts}
          >
            Add
          </button>
          <button
            type="button"
            className="btn btn-secondary"
            onClick={() => {
              goBack()
            }}
            data-dismiss="modal"
          >
            Back
          </button>
        </div>
      </div>

    </div>

  )
}
