import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import PageHeader from "../../components/PageHeader";
import { TENANT_USER } from "../../utils/constants";
import SparkleCard from "../../components/SparkleCard";

import path from 'path'
import ProjectScheduleExtendRequestList from "../Dashbord/ProjectScheduleExtendRequestList";
import ExtensionStatsSummary from "./ExtensionStatsSummary";


export default function TenantUserProjectScheduleExtendDashboard() {

  const dispatch = useDispatch();

  console.log(path.resolve(__dirname, './__test__'));
  //const dashboardData = useFetch(getDashboardDataURL("project-profile-tenant"))




  return (
    <div className="dashboard-container">
      <PageHeader
        HeaderText="Dashboard"
        Breadcrumb={[{ name: "Dashboard" }]}
        
        
      />
      <div className={`dashboard-container-summary`}>
        <ExtensionStatsSummary user_type={TENANT_USER} />
      </div>
      <div className={`dashboard-container-summary`}>
         <ProjectScheduleExtendRequestList user_type={TENANT_USER} />
      </div>
    </div>
  )
}


