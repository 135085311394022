import Moment from 'moment'

export const dateString = (value, formatValue = "h:mm A MMM D, YYYY") =>{

    if(!value) return ""
   
    return Moment((value*1000)).format(formatValue);
} 

export const dateStringShort = (value, formatValue = "h:mm a MMM D") =>{

   try {
       const timeInMiliseconds = value*1000;
       return Moment(timeInMiliseconds).format(formatValue);
   } catch (error) {
    return "";
   }  
} 

export const dateStringFull = (value, formatValue = "h:mm a MMM D, Y ") =>{
    
      
    try {
        const timeInMiliseconds = value*1000;
        return Moment(timeInMiliseconds).format(formatValue);
    } catch (error) {
     return "";
    }  
 } 
 
export const getDateStringOnly = (value, formatValue = "Y MMM D") =>{

    if(value)
    {
        try {
            const timeInMiliseconds = value*1000;
            return Moment(timeInMiliseconds).format(formatValue);
        } catch (error) {
         return "";
        }  
    }

    return ""

    
 } 

 export const getTimeStringOnly = (value, formatValue = "h:mm a ") =>{

    try {
        const timeInMiliseconds = value*1000;
        return Moment(timeInMiliseconds).format(formatValue);
    } catch (error) {
     return "";
    }  
 } 

export const formatDateValue = (value, oldFormat, newFormat) => {

   const format = "h:mm A MMM D, YYYY" 
   const date = new Date(value)
   const newFormatValue = Moment(date).format(format)

   return newFormatValue

}

export const dateInMonthYearFormat = (value) => {

    const format = "MMM YYYY" 
    const date = new Date(value)
    const newFormatValue = Moment(date).format(format)
 
    return newFormatValue
 
 }

export const getDateOneFormatToAnother = (date, newFormat) => {

       return Moment(date).format(newFormat)
 
 }
