import React from "react";
import PageHeader from "../../components/PageHeader";
import {ADMIN_USER} from "../../utils/constants"
import ProjectImplementationDetailsList from "./ProjectImplementationDetailsList";

export default function ProjectImplDetailsDashboard() {



  return (
    <div className="dashboard-container">
      <PageHeader
        HeaderText="Project dashboard"
        Breadcrumb={[{ name: "project-implementation-dashboard", }]}
      />
      <div className={`device-main-container`}>
             
        <ProjectImplementationDetailsList user_type={ADMIN_USER} />
        
      </div>
    </div>
  )
}