import React from 'react';
import { useEffect, useRef, useState } from 'react';
import './Selected.css';

export default function SelectFromOption({
  placeholder = '',
  options = [],
  onClick = () => {},
  displayKey = 'name',
  keyValue = 'id',
  headerClass = 'header-class',
  contentClass = 'content-class',
  readOnly = true,
  fieldError = '',
  disableOption = false,
  value = {},
}) {
  const [tempOptions, updateTempOptions] = useState([]);
  const [listShow, setListShow] = useState(false);
  const [tempValue, updateTempValue] = useState('');
  const divRef = useRef();

  useEffect(() => {
    updateTempValue(value[displayKey] || '');
  }, [value, displayKey]);

  useEffect(() => {
    updateTempOptions(options);
  }, [options]);

  const searchInput = (value) => {
    updateTempValue(value);
  };

  const updateInputValue = (op) => {
    onClick(op);
    setListShow(false);
  };

  const handleListener = (e) => {
    if (!divRef.current.contains(e.target)) {
      setListShow(false);
    }
  };

  useEffect(() => {
    window.addEventListener('click', handleListener);
    return () => window.removeEventListener('click', handleListener);
  }, []);

  return (
    <div ref={divRef} className='selectFromInput'>
      <div onClick={() => !disableOption && setListShow(true)} className={`header ${headerClass}`}>
        <input readOnly={readOnly} placeholder={placeholder} onChange={(e) => searchInput(e.target.value)} type='text' value={tempValue} />
        <div className='text-xl'>
          <i className='fa fa-caret-down '></i>
        </div>
      </div>
      {fieldError && <label className='text-[#dd5a5a] text-sm'>{fieldError}</label>}

      <div className={`listContainer ${contentClass} ${listShow ? 'block' : 'hidden'}`}>
        {tempOptions?.map((op) => (
          <li key={op[keyValue]} onClick={() => updateInputValue(op)}>
            {op[displayKey]}
          </li>
        ))}
      </div>
    </div>
  );
}

// options must be as like as
// const options = [
//     { id: 1, title: "English", value: "english" },
//     { id: 2, title: "Bangla", value: "bangla" },
//     { id: 3, title: "Arabic", value: "arabic" },
//   ];

//onClick e listContainer close && setValue
