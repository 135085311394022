import React, { useState, useEffect } from 'react'
import Button from '@mui/material/Button';
import { useHistory } from "react-router-dom"
import PageHeader from '../../components/PageHeader';
import Spinner from 'react-bootstrap/Spinner'
import ButtonUI from 'react-bootstrap/Button'
import Message from "../../utils/Message";
import { useSelector, useDispatch } from "react-redux";
import { createNewUser } from "../../actions/userCreateAction";



export default function AdminNewUserCreate() {

    const initUserData = {
        user_type: "0", email: "", password: "",description: "", name: ""
    }
    const history = useHistory();

    const userList = () => {
        history.push("/admin-user-list")
    }

    const dispatch = useDispatch()
    const [userData, setUserData] = useState({ ...initUserData })
    const message = {
        show: false,
        title: "",
        message: "",
        messageType: "danger",
    }
    const [messageDialog, setMessageDialog] = useState(message)
    const createNewAccountReducer = useSelector(state => state.createNewAccountReducer)

    useEffect(() => {

        if (createNewAccountReducer.showMessage) {
          setMessageDialog({
            ...messageDialog,
            ...createNewAccountReducer.updatedMessageDialog
          })
        }
    
      }, [createNewAccountReducer])

    const handleOnchange = (e) => {
        const fieldName = e.target.name
        const fieldValue = e.target.value
        setUserData({
            ...userData,
            [fieldName]: fieldValue
        })
    }

    const submitUpdate = (e) => {
        e.preventDefault();
        dispatch(createNewUser(userData))
    }

    const handleUserType = (value) => {

        setUserData({
            ...userData,
            user_type: value
        })
    }

    return (

        <div className="dashboard-container">
            <PageHeader
                HeaderText="Create new user"
                Breadcrumb={[{ name: "Management" }, { name: "Create new user" }]}
            />
            <div className="col-lg-12">
                <div className="card">
                    <div className="header align-space-between-side">
                        <h6>Fill the user credentials form</h6>
                        <Button onClick={userList} variant="contained">Back to user list</Button>

                    </div>
                    <div className="body">
                        <div className="row clearfix">
                            <div className="col-lg-6 col-md-12">

                                <div className="form-group">
                                    <span>Select user type</span>
                                    {(<div className="flex-middle">
                                        <select onChange={(val) => handleUserType(val.target.value)} className="custom-select" id="inputGroupSelect01">
                                            <option value="0" selected>Please choose account type...</option>
                                            <option value="ADMIN_USER" >Admin account</option>
                                            <option value="TENANT_USER">Investor account</option>
                                            <option value="PD_USER">Project Director(PD) account</option>
                                           
                                        </select>
                                    </div>)}

                                </div>

                                <div className="form-group">
                                    <span>Email*</span>
                                    <input
                                        className="form-control"
                                        placeholder=""
                                        name="email"
                                        value={userData.email}
                                        type="text"
                                        onChange={(e) => handleOnchange(e)}
                                    />
                                </div>
                                <div className="form-group">
                                    <span>Password*</span>
                                    <input
                                        className="form-control"
                                        placeholder=""
                                        name="password"
                                        value={userData.password}
                                        type="password"
                                        onChange={(e) => handleOnchange(e)}
                                    />
                                </div>
                                <div className="form-group">
                                    <span>Name</span>
                                    <input
                                        className="form-control"
                                        placeholder=""
                                        name="name"
                                        value={userData.name}
                                        type="text"
                                        onChange={(e) => handleOnchange(e)}
                                    />
                                </div>

                                <div className="form-group">
                                    <span>Description(Optional)</span>
                                    <input
                                        className="form-control"
                                        placeholder=""
                                        name="description"
                                        value={userData.description}
                                        type="text"
                                        onChange={(e) => handleOnchange(e)}
                                    />
                                </div>

                                <div className="form-group">

                                    {(messageDialog.show && !createNewAccountReducer.isLoading) && <Message data={messageDialog} />}

                                </div>

                                <div >

                                    <ButtonUI onClick={(e) => { submitUpdate(e) }} variant="primary">
                                        {
                                            createNewAccountReducer.isLoading && <Spinner
                                                as="span"
                                                animation="grow"
                                                size="sm"
                                                role="status"
                                                aria-hidden="true"
                                            />
                                        }

                                        { createNewAccountReducer.isLoading ? "Loading..." : "Create new account"}
                                    </ButtonUI>

                                </div>
                            </div>
                        </div>
                    </div>

                </div>


            </div>
        </div>



    )
}
