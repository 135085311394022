import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import PageHeader from "../../components/PageHeader";
import { ADMIN_USER, TENANT_USER } from "../../utils/constants";
import path from 'path'
import { useFetch } from "../../customHooks/fetchData"
import { getDashboardDataURL } from "../../utils/allurls"
import ChatSupportTexting from "./ChatSupportTexting";
import Button from 'react-bootstrap/Button'
import { userType } from "../../utils/userUtils"
import { useHistory, useLocation } from "react-router"
import { updateProjectSupportRequestStatus } from "../../actions/projectSupportAction"
import swal from 'sweetalert';
import StatusInfo from "../../utils/StatusInfo";
import SupportSummary from "./SupportSummary";
import { getProjectUpdateJsonBody } from "../../utils/GeneralUtils"

export default function ChatSupportDashbord(props) {

  const { chat_support } = props

  console.log("chat_support", chat_support)

  const dispatch = useDispatch();
  const history = useHistory()
  const location = useLocation()

  const supportObject = location.state
  console.log("supportObject", supportObject)
  const projectSupportReducer = useSelector(state => state.projectSupportReducer)


  const projectUpdateSuccess = (success) => {

    if (success) {
      if(userType() === ADMIN_USER)
          {
             history.push("admin-support-dashboard")
          }else
          {
            history.push("support-dashboard")
          }
    }

  }



  const getAdminActionUI = () => {


    if (supportObject.status === 'open') {

      return (<div className={"display-space-around"}>
        <Button variant="secondary" onClick={() => {

          const jsonBody = getProjectUpdateJsonBody(supportObject.id, "closed")

          dispatch(updateProjectSupportRequestStatus(jsonBody))


        }} >{"Close"}</Button>

        <Button variant="primary" onClick={() => {

          const jsonBody = getProjectUpdateJsonBody(supportObject.id, "resolved")

          dispatch(updateProjectSupportRequestStatus(jsonBody))




        }} >{"Resolve"}</Button>

      </div>)

    } else {
      return (<div className={"display-space-around"}>

        <StatusInfo status={supportObject.status} />
      </div>)
    }


  }

  const getUserActionUI = () => {

    if (supportObject.status === 'open') {
      return (<div className={"display-space-around"}>

        {supportObject.status && <Button variant="secondary" onClick={() => {

          const jsonBody = getProjectUpdateJsonBody(supportObject.id, "closed")
          dispatch(updateProjectSupportRequestStatus(jsonBody))


        }} >{"Close"}</Button>}

      </div>)
    } else {
      return (<div className={"display-space-around"}>

        <StatusInfo status={supportObject.status} />
      </div>)
    }



  }


  return (
    <div className="dashboard-container">
      <PageHeader
        HeaderText="Messages"
        Breadcrumb={[{ name: "messages" }]}
      />
      <div className={`displayVertical`}>
        <div className={"display-two-column"}>
          <div><Button variant="secondary" onClick={() => {

            if (userType() === ADMIN_USER) {
              history.push("admin-support-dashboard")
            } else {
              history.push("support-dashboard")
            }


          }} >{"Back"}</Button></div>


          {userType() === ADMIN_USER && getAdminActionUI()}
          {userType() === TENANT_USER && getUserActionUI()}

        </div>
        <div className={"margin-top-bottom"}>
          <SupportSummary supportObject={supportObject} />
        </div>


      </div>
      <div>
        <ChatSupportTexting supportObject={supportObject} />
        {projectUpdateSuccess(projectSupportReducer.updateSuccess)}


      </div>
    </div>
  )
}


