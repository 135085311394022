
export const userList = [
    { sl: "100001", name: "THiNK Limited", projectName: "iOT device manufacture", description: "Desc 1", createAt: "Mar 12, 2022", email: "test@gmail.com" },
    { sl: "100002", name: "Bell Limited", projectName: "Mobile manufacture", description: "Desc 1", createAt: "Mar 12, 2022", email: "test@gmail.com" },
    { sl: "100003", name: "Green Limited", projectName: "ATM manufacture", description: "Desc 1", createAt: "Mar 12, 2022", email: "test@gmail.com" },
    { sl: "100004", name: "Company1 Limited", projectName: "iOT device manufacture", description: "Desc 1", createAt: "Mar 12, 2022", email: "test@gmail.com" },
    { sl: "100005", name: "Company2 Limited", projectName: "iOT device manufacture", description: "Desc 1", createAt: "Mar 12, 2022", email: "test@gmail.com" },
    { sl: "100006", name: "Company3 Limited", projectName: "iOT device manufacture", description: "Desc 1", createAt: "Mar 12, 2022", email: "test@gmail.com" },
    { sl: "100007", name: "Company4 Limited", projectName: "iOT device manufacture", description: "Desc 1", createAt: "Mar 12, 2022", email: "test@gmail.com" },
]

export const fiscalYearList = () =>{

    const yearList = []
    for(let i=2010; i<2040; i++)
    {
        yearList.push({year:i, fiscalYearFormat: `${i-1}-${i}`})
    }

    return yearList

}